import React, { useState, useEffect, Component } from 'react';
import {useParams, Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import edgeLanding from '../../assets/images/edge-landing.jpg';
import edgeQuote from '../../assets/images/edge-quote.jpg';
import edgeRed from '../../assets/images/edge-red.png';
import edgeWhite from '../../assets/images/edge-white.png';
import edgeCare from '../../assets/images/care.jpg';
import edgeControl from '../../assets/images/control.jpg';
import edgeBrochure from '../../assets/images/edge-brochure.jpg';
import randomInteger from 'random-int';
import $ from 'jquery';
import figo from '../../assets/images/figo.png';
import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'

const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });



const Variants = () => {

  const params = useParams()

  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: "",
    validateCriteriaMode: "all",
    submitFocusError: true,
    nativeValidation: false,
});
    
const {
  register: register1,
  handleSubmit: handleSubmit1,
  errors: errors1,
  control: control1,
  reset: reset1,
} = useForm({
  mode: "onSubmit",
  reValidateMode: "onChange",
  defaultValues: "",
  validateCriteriaMode: "all",
  submitFocusError: true,
  nativeValidation: false,
});


let requestBrochure = async function (data){
      
          
     
          
  let submitBrochureRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/brochure',{ 
      method: "POST",
      body:JSON.stringify(data),
      headers:{
        'Content-Type':'application/JSON',
        'Accept': 'application/JSON'
      },
      mode:'cors' 
    })
  
    .then(response => response.json())
    .then(response => { 
        alert("request sent")

    })
   


};


let requestQuote = async function (data){
      

      
          
  let submitQuoteRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/quotation',{ 
      method: "POST",
      body:JSON.stringify(data),
      headers:{
        'Content-Type':'application/JSON',
        'Accept': 'application/JSON'
      },
      mode:'cors' 
    })
  
    .then(response => response.json())
    .then(response => { 
        alert("request sent")

    })
   


};

  // yuo can find all params from here
  console.log(params)
  window.scrollTo(0, 0)
  const [turnRecommenderOff, setTurnRecommenderOff] = useState(false);
  const [variant, setVariant] = useState("");
  const [variantId, setVariantId] = useState(params.id);
  const [variantName, setVariantName] = useState("");
  const [variantBanner, setVariantBanner] = useState("");
  const [variantPriceLow, setVariantPriceLow] = useState("");
  const [variantPriceHigh, setVariantPriceHigh] = useState("");
  const [variantTransmission, setVariantTransmission] = useState("");
  const [variantTravelSize, setVariantTravelSize] = useState("");
  const [variantFuelEconomy, setVariantFuelEconomy] = useState("");
  const [variantRunningCarLeft, setVariantRunningCarLeft] = useState("");
  const [variantRunningCarRight, setVariantRunningCarRight] = useState("");
  const [variantMarketingCopy, setVariantMarketingCopy] = useState("");
  const [variantMarketingDescription, setVariantMarketingDescription] = useState("");
  const [variantMarketingSubCopy2, setVariantMarketingSubCopy2] = useState("");
  const [variantMarketingCopy2, setVariantMarketingCopy2] = useState("");
  const [variantFeaturedImages, setVariantFeaturedImages] = useState([]);
  const [variantQuoteImage, setVariantQuoteImages] = useState("");
  const [variantBrochureImages, setVariantBrochureImages] = useState("");
  const [variantGalleryImages, setVariantGalleryImages] = useState([]);
  const [vehicleRange1, setVehicleRange1] = useState([]);
  const [vehicleRange2, setVehicleRange2] = useState([]);






 function handleChange(event) {
    setVariant(event.target.value);
  }

  let vehicleArray=[]
  let renderCount = 0 

function gallerySwitch(){
  window.scrollTo(0, 0)
  $("#overview, #home-tab").removeClass('active')
  $("#gallery, #profile-tab").addClass('show active')

}
 

  async function sendVariantHistory(bannerImage, variantName, price, categoryId, category){ 

    let variantParameters = { 
      bannerImageData : bannerImage,
      variantNameData : variantName,
      priceData : price, 
      variantCategoryIdData : categoryId,
      variantCategoryData : category 
    }

    console.log('history submission ran')

  const submitVariantHistoryData = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/personalisationd/variantData',{ 

  method:"POST",
  headers:{ 
  "content-type" : "application/json"
  },
  body: JSON.stringify(variantParameters),
  mode:"cors",
  credentials:"include"

  })
  .then(response => response.json())
  .then(response => { 
    console.log('response ran')
      console.log(response)  
  })
 }

  useEffect(() => {   
    
    
    client
    .query({
      query: gql`
       query($id:ID!){
        fordvariantBy(id:$id) {
          variants {
            descriptiveCaption
            fieldGroupName
            fuelEconomy
            marketingCopy
            marketingCopy2
            subCopyMarketingCopy2
            priceRangeMaximum
            priceRangeMinimum
            leftRunningVehicleTopView{
              mediaItemUrl
            }
            rightRunningVehicleTopView{
              mediaItemUrl
            }
            transmission
            travelSize
            variantName
            coverImage{
              mediaItemUrl
            }
            featuredImages{
             featuredImages{
                id
                mediaItemUrl
            }
         
            }
            galleryImages{
           
                id
                mediaItemUrl

            }
            brochureSectionCoverImage{
              mediaItemUrl
            }
            requestQuoteSectionCoverImageCopy{
              mediaItemUrl
            }
          }
      }
        }
     
      `,
      variables: {id:params.id}
      })
    .then(result => {

      let bannerImage, variantName, price, categoryId, category
     
      bannerImage = result.data.fordvariantBy.variants.coverImage.mediaItemUrl
      variantName = result.data.fordvariantBy.variants.variantName
      price = result.data.fordvariantBy.variants.priceRangeMinimum
      categoryId = params.id
      category = params.category
 
      
       console.log(result.data.fordvariantBy.variants.featuredImages[0].featuredImages)
       setVariantName(result.data.fordvariantBy.variants.variantName)
       setVariantBanner(result.data.fordvariantBy.variants.coverImage.mediaItemUrl)
       setVariantBrochureImages(result.data.fordvariantBy.variants.brochureSectionCoverImage.mediaItemUrl)
       setVariantQuoteImages(result.data.fordvariantBy.variants.requestQuoteSectionCoverImageCopy.mediaItemUrl)
       setVariantPriceLow(result.data.fordvariantBy.variants.priceRangeMinimum)
       setVariantPriceHigh(result.data.fordvariantBy.variants.priceRangeMaximum)
       setVariantTransmission(result.data.fordvariantBy.variants.transmission)
       setVariantTravelSize(result.data.fordvariantBy.variants.travelSize)
       if(result.data.fordvariantBy.variants.leftRunningVehicleTopView){
        setVariantRunningCarLeft(result.data.fordvariantBy.variants.leftRunningVehicleTopView.mediaItemUrl)
        setVariantRunningCarRight(result.data.fordvariantBy.variants.rightRunningVehicleTopView.mediaItemUrl) 
       }
       setVariantMarketingCopy(result.data.fordvariantBy.variants.marketingCopy)
       setVariantMarketingCopy2(result.data.fordvariantBy.variants.marketingCopy2)
       setVariantMarketingSubCopy2(result.data.fordvariantBy.variants.subCopyMarketingCopy2)
       setVariantMarketingDescription(result.data.fordvariantBy.variants.descriptiveCaption)
       setVariantFeaturedImages(result.data.fordvariantBy.variants.featuredImages[0].featuredImages)
       setVariantGalleryImages(result.data.fordvariantBy.variants.galleryImages)
    
     
       console.log(variantGalleryImages)

       sendVariantHistory(bannerImage, variantName, price, categoryId, category)
       

    })






    client
    .query({
      query: gql`
      query{
          fordvariants {
              edges {
                node {
                  id
                  title
                  categories{
                    edges{
                      node{
                        id
                        name
                      }
                    }
                  }
                  variants {
                    fieldGroupName
                    descriptiveCaption
                    fieldGroupName
                    fuelEconomy
                    marketingCopy
                    priceRangeMaximum
                    priceRangeMinimum
                    transmission
                    travelSize
                    variantName
                    leftRunningVehicleTopView{
                      mediaItemUrl
                    }
                    rightRunningVehicleTopView{
                      mediaItemUrl
                    }
                    coverImage {
                      mediaItemUrl
                    }
                    featuredImages {
                      featuredImages {
                        id
                        mediaItemUrl
                      }
                    }
                    galleryImages {
                    
                        id
                        mediaItemUrl
                
                    }
                    brochureSectionCoverImage {
                      mediaItemUrl
                    }
                    requestQuoteSectionCoverImageCopy {
                      mediaItemUrl
                    }
                  }
                  id
                }
              }
            }
      }
  
      `,
    })
    .then(result => {
  
   
    
      let vehicleDetailPack
      
      console.log(result.data)
      console.log(vehicleArray)
       vehicleArray =[]
    
      for( let i=0; i < result.data.fordvariants.edges.length; i++){
       
        if(result.data.fordvariants.edges[i].node.categories.edges[0].node.name  === params.category){
             console.log(result.data.fordvariants.edges[i].node.categories.edges[0].node.name)
               vehicleDetailPack =[{url:result.data.fordvariants.edges[i].node.variants.coverImage.mediaItemUrl, id: result.data.fordvariants.edges[i].node.id, variantName: result.data.fordvariants.edges[i].node.variants.variantName, marketingCopy: result.data.fordvariants.edges[i].node.variants.marketingCopy, category: result.data.fordvariants.edges[i].node.categories.edges[0].node.name }]
             
              
               console.log(vehicleArray.length)
         
                vehicleArray.push(vehicleDetailPack)
              
     
             
            
        }
  
    
     }

     


  

  let arrayInt0
  let arrayInt1
  let vehicleArrayLength

//check if there are vehicles in the array to allocate to the recommender section
if(vehicleArray.length  === 1){

  console.log(vehicleArray.length)
  //set vehicleRange variable to enable jsx return
  setVehicleRange1(vehicleArray[0])
 
  setTurnRecommenderOff(true)
}else if(vehicleArray.length > 1){
    console.log(vehicleArray.length)
    console.log(vehicleArray)
     
       arrayInt0  =  randomInteger(0, vehicleArray.length - 1)
      arrayInt1 = randomInteger(0, vehicleArray.length - 1)

      while(arrayInt0 === arrayInt1){
        arrayInt1 = randomInteger(0, vehicleArray.length - 1)
      }
       console.log(vehicleArray[arrayInt0][0].variantName)
      //solving the scenario of name collision
      if(params.variantName === vehicleArray[arrayInt0][0].variantName){
        let increasedArrayInt0 = arrayInt0 + 1
        let decreasedArrayInt0 = arrayInt0 - 1
            vehicleArrayLength = vehicleArrayLength - 1
           if(arrayInt0 > arrayInt1 && increasedArrayInt0 <= vehicleArrayLength){
              arrayInt0 = increasedArrayInt0
           }

           if(arrayInt0 < arrayInt1 && decreasedArrayInt0 >= 0){
            arrayInt0 = decreasedArrayInt0
         }
      }

      if(params.variantName === vehicleArray[arrayInt1][0].variantName){
        let increasedArrayInt1 = arrayInt1 + 1
        let decreasedArrayInt1 = arrayInt1 - 1
            vehicleArrayLength = vehicleArrayLength - 1
           if(arrayInt1 > arrayInt0 && increasedArrayInt1 <= vehicleArrayLength){
              arrayInt1 = increasedArrayInt1
           }

           if(arrayInt1 < arrayInt0 && decreasedArrayInt1 >= 0){
            arrayInt1 = decreasedArrayInt1
         }
      }

      console.log(arrayInt0)
      console.log(arrayInt1)


 setVehicleRange1(vehicleArray[arrayInt0])
 setVehicleRange2(vehicleArray[arrayInt1])

    }
  
     });
     console.log(vehicleRange1)
     console.log(vehicleRange2)
    }, [])


 //extract featured image

 let variantFeaturedImagesDetails;

 if(variantFeaturedImages.length){ 

  variantFeaturedImagesDetails = variantFeaturedImages.map((variantFeaturedImagesDetails, i) => 

  <div className="col-12">
  <img src={variantFeaturedImagesDetails.mediaItemUrl} className="pOff img-fluid" />
  </div>
      
   )

  
}


//extract gallery Image


let variantGalleryImagesDetails;

if(variantGalleryImages.length){ 

 variantGalleryImagesDetails = variantGalleryImages.map((variantGalleryImagesDetails, i) => 

<div className='col-12 col-md-4 pOff'>
<img src={variantGalleryImagesDetails.mediaItemUrl} className="pOff four full-width img-fluid" />
</div>
     
  )

 
}

if(vehicleRange1.length){
  let recommendationSection
  if(!turnRecommenderOff){
     recommendationSection = <div className='row navigation-clearance-plus text-center full-width mOff' id="recommenderSection">

      <div className="col-12 ">
        <h3 className='ford-blue-font light-font-weight'> You might also like...  </h3>
      </div>

        
    <div className="col-12 col-md-5 mx-auto standard-margin-bottom">
        <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text text-left'>{vehicleRange1[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom'  href={`/brands/variants/${vehicleRange1[0].category}/${vehicleRange1[0].id}/${vehicleRange1[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>  <figure>
      <figcaption className='text-right standard-section-margin'>
      <img src={vehicleRange1[0].url} width="100%"/>
      </figcaption>
      
    
        </figure>
    
      </div>

      <div className="col-12 col-md-5 mx-auto standard-margin-bottom">
        <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text text-left'>{vehicleRange2[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${vehicleRange2[0].category}/${vehicleRange2[0].id}/${vehicleRange2[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>  <figure>
      <figcaption className='text-right standard-section-margin'>
      <img src={vehicleRange2[0].url} width="100%"/>
      </figcaption>
      
    
        </figure>
    
      </div>
  

    </div>
    
  }else{
    recommendationSection = " "
  }
    return(
        <>
        <div className="row ford-blue-bg navigation-clearance mobile-margin-clearance-banner full-width mROff">
            <div className="variant col-12 col-md-3 text-center ford-royal-blue-bg">
            <h5 className='mOff variant-text'> Explore {variantName} </h5>
            </div>
              <div className="col-12 col-md-9 pOff">
              <ul class="nav nav-tabs variant-menu-padding full-width variant-menu-height ford-font" id="myTab" role="tablist">
  <li class="nav-item quarter-length" role="presentation">
    <button class="nav-link active variant-menu-height full-width variant variant-button" id="home-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="home" aria-selected="true">Overview</button>
  </li>
  <li class="nav-item quarter-length" role="presentation">
    <button class="nav-link variant-menu-height full-width variant variant-button" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gallery" type="button" role="tab" aria-controls="profile" aria-selected="false">Gallery</button>
  </li>
  <li class="nav-item quarter-length" role="presentation">
    <button class="nav-link variant-menu-height full-width variant variant-button" id="contact-tab" data-bs-toggle="tab" data-bs-target="#brochure" type="button" role="tab" aria-controls="contact" aria-selected="false">Brochure</button>
  </li>
  <li class="nav-item quarter-length" role="presentation">
    <button class="nav-link variant-menu-height full-width variant variant-button" id="contact-tab" data-bs-toggle="tab" data-bs-target="#quote" type="button" role="tab" aria-controls="contact" aria-selected="false">Request quote</button>
  </li>
</ul>
              </div>
            </div>
      <div className="row ford-font full-width mOff">  
      <div class="tab-content pOff" id="myTabContent">
  <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="home-tab">
  <div className="row full-width mOff">
    <div className="col-12 pOff">
        <img src={variantBanner} className="pOff img-fluid" />
    </div>
  </div>

  <div className="row white-bg ford-font full-width mOff ">
  <div className="col-12 col-md-5 variant-specs-box variant-specs-border">
    <h6 className='ford-blue-text'> Price Range </h6>
    <h3 className='ford-royal-blue-text'> {variantPriceLow} - {variantPriceHigh} </h3>

  </div>
  <div className="col-12 col-md-2 variant-specs-box variant-specs-border">
    <h6 className='ford-blue-text'> Transmission </h6>
    <h3 className='ford-royal-blue-text'> {variantTransmission}</h3>
  </div>
  <div className="col-12 col-md-2 variant-specs-box variant-specs-border">
    <h6 className='ford-blue-text'> Travel Size </h6>
    <h3 className='ford-royal-blue-text'> {variantTravelSize} </h3>
  </div>
  <div className="col-12 col-md-2 variant-specs-box">
    <h6 className='ford-blue-text'> Engine Size </h6>
    <h3 className='ford-royal-blue-text'>  </h3>
  </div>
    </div>

    <div className='row navigation-clearance-plus ford-font text-center full-width mROff'> 
    <img src={variantRunningCarLeft} className="pOff leftHighwayPosition img-fluid"  />
    <img src={variantRunningCarRight} className="pOff rightHighwayPosition img-fluid" />
        <div className='col-12 col-md-6 mx-auto'> 
             <h1 className='ford-blue-text light-font-weight'> {variantMarketingCopy} </h1>
             <h6 className='ford-grey-text light-font-weight'>      
               {variantMarketingDescription}  </h6>
             <div className='col-12 text-center standard-margin-bottom'>
                            <button className='btn btn-ford-blue' onClick={gallerySwitch}> Visit Gallery </button>
                            </div>
                            <div className='col-12 text-center'>
                            <a class="nav-link nav-font-size" href={`/test-drive/${variantName}`} target="_blank">
                            <button className='btn btn-ford-blue'> Test Drive </button>
                            </a>
                            </div>
        </div>

        <div className="col-12 col-md-9 mx-auto navigation-clearance-plus"> 
        <h1 className='ford-blue-text light-font-weight'> {variantMarketingCopy2} </h1>
        <h6 className='ford-grey-text light-font-weight'> 
             {variantMarketingSubCopy2}
        </h6>
        
        </div>

       
       {variantFeaturedImagesDetails}


    
    </div>



   {recommendationSection}

 

  </div>

  

  <div class="tab-pane fade navigation-clearance" id="gallery" role="tabpanel" aria-labelledby="profile-tab">
  <div className='row full-width mOff'>
      
        <div className="col-12 standard-text-padding-alt ford-grey text-center">
           <h1 className="ford-font ford-grey-text light-font-weight"> PHOTO GALLERY </h1>
           <h6 className="ford-font ford-grey-text light-font-weight"> Visit our instagram Page for more visuals from Ford Nigeria </h6>
        </div>

      
       {variantGalleryImagesDetails}
        
        </div>
    </div>

  <div class="tab-pane fade" id="brochure" role="tabpanel" aria-labelledby="contact-tab">

   <div className='row mOff full-width'>
        <div className='col-12 pOff mOff'>
        <img src={variantBrochureImages} className="pOff img-fluid" />
        </div>
        <div className="col-12 standard-text-padding-alt ford-grey text-center">
           <h3 className="ford-font ford-grey-text light-font-weight"> Need more information on your new Ford Edge ? </h3>
           <h6 className="ford-font ford-grey-text light-font-weight"> Provide details to download the official brochure right now! </h6>
        </div>

        <div className="col-12">

        <div className='p-5 text-center'>
            <form className='col-12 col-6 mx-auto' onSubmit={handleSubmit(requestBrochure)}>

                    <input className='form-control' type="text" placeholder='First name' {...register("firstname", {required:true})}/>

                    <input className='form-control' type="text" placeholder='Surname' {...register("lastname", {required:true})}/>

                    <input className='form-control' type="email" placeholder='Email' {...register("email", {required:true})}/>

                    <input className='form-control' type="text" placeholder='Mobile No' {...register("mobileno", {required:true})} />


                    <input className='form-control' type="text" value={variantName} placeholder='variant' {...register("variant", {required:true})} />


                    <div className='col-12 text-right'>
                    <button className='btn btn-ford-blue'> Download Brochure  </button>
                    </div>
            </form>
        </div>

</div>
      </div>

    </div>

    <div class="tab-pane fade" id="quote" role="tabpanel" aria-labelledby="contact-tab">
      <div className='row full-width mOff'>
        <div className='col-12 mOff pOff'>
        <img src={variantQuoteImage} className="pOff img-fluid" />
        </div>
        <div className="col-12 standard-text-padding-alt ford-grey text-center">
           <h3 className="ford-font ford-grey-text light-font-weight"> Get a price quote for your new Ford Edge </h3>
           <h6 className="ford-font ford-grey-text light-font-weight"> Specify details as they should indicated on official Quotation </h6>
        </div>

        <div className="col-12">

<div className='p-5 text-center'>
            <form className='col-12 col-6 mx-auto'onSubmit={handleSubmit1(requestQuote)}>

                    <input className='form-control' type="text" placeholder='First name' {...register1("firstname", {required:true})}/>

                    <input className='form-control' type="text" placeholder='Surname' {...register1("lastname", {required:true})}/>

                    <input className='form-control' type="email" placeholder='Email' {...register1("email", {required:true})}/>

                    <input className='form-control' type="text" placeholder='Mobile No' {...register1("mobileno", {required:true})}/>

                    <input className='form-control' type="text" placeholder='Mailing Address' {...register1("address", {required:true})}/>
 
                    <input className='form-control' type="text" value={variantName} placeholder='variantName' {...register1("variant", {required:true})} />

                    <div className='col-12 text-right'>
                    <button className='btn btn-ford-blue'> Request Quote </button>
                    </div>
            </form>
        </div>

</div>
      </div>

    </div>

</div>
  

      </div>

        </>
        
    )
}

}

export default Variants;