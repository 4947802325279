import React, { useState, useEffect, Component } from 'react';
import '../blog/blog.css';
import blog1 from '../../assets/images/blog1.png';
import geepFord from '../../assets/images/geepford.png';
import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });



    


const FordBlog = () => {

   
    let newsArray =[]
    const [blogPostDetails, setBlogPostDetails] = useState([])
    const [blogPostDetails2, setBlogPostDetails2] = useState([])
 

    useEffect(() => {
           
          client
        .query({
          query: gql`
          query{
            allNews {
                nodes {
                  new {
                    featuredImage {
                      mediaItemUrl
                    }
                    newsBody
                    newsHeadline
                  }
                  categories {
                    edges {
                      node {
                        categoryId
                        name
                      }
                    }
                  }
                  id
                }
              }
          }
      
          `,
        })
        .then(async function (result) { 

           let blogDetailsArray 

           console.log(result.data.allNews.nodes.length)
           if(newsArray.length < 2){
           for( let i=0; i <= result.data.allNews.nodes.length - 1; i++){
            console.log(result.data.allNews.nodes[0].categories.edges[0].node.categoryId)
            console.log(result.data.allNews.nodes[0].categories.edges[0].node.name)
        
       
          let   newsDetailPack =[{headline:result.data.allNews.nodes[i].new.newsHeadline, url:result.data.allNews.nodes[i].new.featuredImage.mediaItemUrl, id: result.data.allNews.nodes[i].id, categoryName: result.data.allNews.nodes[0].categories.edges[0].node.name }]
                
            newsArray.push(newsDetailPack)
            console.log(newsArray)

    setBlogPostDetails2(newsArray)

    }
  }
   
  
         
        })

       
    }, [] )

    window.scrollTo(0, 0)
 

    let newsArrayDetails
if(blogPostDetails2.length > 0){
   
      console.log(blogPostDetails2.length)
   
    
        newsArrayDetails = blogPostDetails2.map((newsArrayDetails, i) => 
           
        <div className='row mb-5 blogContent'>
        <div className='col-12 col-md-6'>
            <img src={newsArrayDetails[0].url} alt="blogimage" width="100%"/>
        </div>

        <div className='col-12 col-md-6'>
            <p></p> 
            <h2>{newsArrayDetails[0].headline}</h2>  
            <p>Finding a parking space and then manoeuving into one can be a headache! 
                Especailly with some spaces not much wider than vechicle itself: 
                often it doesnt leave much room for getting in and out without risking damage to the doors
            </p>

            <a href={`/blog/news/${newsArrayDetails[0].id}/${newsArrayDetails[0].categoryName}`} className="noDecor2">Read More <i class="fa-solid fa-angle-right"></i></a> 
        </div>
    </div>
    
  
            
         )
    

}

  


if(blogPostDetails2.length > 0){


    
    return (
        <>
            <div className="forBlog navigation-clearance mobile-margin-clearance-banner">
                <h5 className='mOff'>Ford Blog</h5>
            </div>
            <div className="newsHeading text-center">
                <p>The Home Ford News and stories. From car launches to finance plans and aftersales offerings.
                    All the performance thrills you need
                </p>
            </div>

            <div className='container'>
                
        {newsArrayDetails}

             
            </div>
        </>
    )
    }

}


export default FordBlog;