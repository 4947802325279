import React, { useState, useEffect, Component } from 'react';
import fordlogo from '../../assets/images/Ford_logo.png';
import cancel from '../../assets/images/cancel.png';
import '../../component/navBar/navbar.css';
import $ from 'jquery';
import { Close, MenuOutlined } from '@material-ui/icons';
//import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
//import '../../../node_modules/bootstrap/dist/js/bootstrap.js';
import { Link, NavLink} from 'react-router-dom';
import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'

const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });



const Navbar = () => {

const [isopen, setIsopen] = useState(false);
const [carRange, setCarRange] = useState([]);
const [suvRange, setSuvRange] = useState([]);
const [vanRange, setVanRange] = useState([]);

useEffect(() => {

  $('#car-button').click(function(){

    $('#suvs, #vans').removeClass('show')
  })

  $('#suv-button').click(function(){

    $('#cars, #vans').removeClass('show')
  })


  $('#van-button').click(function(){

    $('#cars, #suvs').removeClass('show')
  })

  $('#close').click(async function(){
    setIsopen(false)
    $('#vans, #suvs, #cars').removeClass('show')
  
    
  })
     
     
    client
  .query({
    query: gql`
    query{
        fordvariants {
            edges {
              node {
                id
                title
                categories{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
                variants {
                  fieldGroupName
                  descriptiveCaption
                  fieldGroupName
                  fuelEconomy
                  marketingCopy
                  priceRangeMaximum
                  priceRangeMinimum
                  transmission
                  travelSize
                  variantName
                  leftRunningVehicleTopView{
                    mediaItemUrl
                  }
                  rightRunningVehicleTopView{
                    mediaItemUrl
                  }
                  coverImage {
                    mediaItemUrl
                  }
                  featuredImages {
                    featuredImages {
                      id
                      mediaItemUrl
                    }
                  }
                  galleryImages {
                  
                      id
                      mediaItemUrl
              
                  }
                  brochureSectionCoverImage {
                    mediaItemUrl
                  }
                  requestQuoteSectionCoverImageCopy {
                    mediaItemUrl
                  }
                }
                id
              }
            }
          }
    }

    `,
  })
  .then(result => {

    let carsArray=[]
    let carDetailPack
    let vansArray=[]
    let vanDetailPack
    let suvArray=[]
    let suvDetailPack
    console.log(result.data)

    for( let i=0; i < result.data.fordvariants.edges.length; i++){
        console.log(result.data.fordvariants.edges[0].categories)
    if( result.data.fordvariants.edges[i].node.categories.edges[0].node.name  === "Cars"){
           carDetailPack =[{url:result.data.fordvariants.edges[i].node.variants.coverImage.mediaItemUrl, id: result.data.fordvariants.edges[i].node.id, variantName: result.data.fordvariants.edges[i].node.variants.variantName, marketingCopy: result.data.fordvariants.edges[i].node.variants.marketingCopy, category: result.data.fordvariants.edges[i].node.categories.edges[0].node.name }]
        carsArray.push(carDetailPack)
    }

    if( result.data.fordvariants.edges[i].node.categories.edges[0].node.name  === "SUVs"){
        console.log('suv found')
        suvDetailPack =[{url:result.data.fordvariants.edges[i].node.variants.coverImage.mediaItemUrl, id: result.data.fordvariants.edges[i].node.id, variantName: result.data.fordvariants.edges[i].node.variants.variantName, marketingCopy: result.data.fordvariants.edges[i].node.variants.marketingCopy, category: result.data.fordvariants.edges[i].node.categories.edges[0].node.name }]
     suvArray.push(suvDetailPack)
 }

 if( result.data.fordvariants.edges[i].node.categories.edges[0].node.name  === "Vans&Trucks"){
    vanDetailPack =[{url:result.data.fordvariants.edges[i].node.variants.coverImage.mediaItemUrl, id: result.data.fordvariants.edges[i].node.id, variantName: result.data.fordvariants.edges[i].node.variants.variantName, marketingCopy: result.data.fordvariants.edges[i].node.variants.marketingCopy, category: result.data.fordvariants.edges[i].node.categories.edges[0].node.name }]
 vansArray.push(vanDetailPack)
}
}

console.log(carsArray)
console.log(vansArray)
console.log(suvArray)

setCarRange(carsArray)
setVanRange(vansArray)
setSuvRange(suvArray)


   });



   

 }, [])

const showMenu = () => {
    setIsopen(!isopen);
}


const closeDesktopMenu = () => { 
      $('.car-navigation-panel').fadeOut('fast')
      $('#home-tab, #profile-tab, #contact-tab, #vans-tab').removeClass('active')
}

const openDesktopMenu = () => { 
    $('.car-navigation-panel').fadeIn('fast')
}

let carRangeDetails
let suvRangeDetails
let vanRangeDetails

if(carRange.length){ 

    if(carRange.length > 1){

    carRangeDetails = carRange.map((carRangeDetails, i) => 
 

    <div key={i} className="col-12 col-md-4 mx-auto standard-margin-bottom">
                 <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text'>{carRangeDetails[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${carRangeDetails[0].category}/${carRangeDetails[0].id}/${carRangeDetails[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>  <figure>
      <figcaption className='text-right standard-section-margin'>
      <img src={carRangeDetails[0].url} width="90%"/>
      </figcaption>
      
    
        </figure>
    
      </div>
        
     )

    }

    if(carRange.length === 1){
                
        carRangeDetails = carRange.map((carRangeDetails, i) => 
     
    
        <div key={i} className="col-8 mx-auto standard-margin-bottom text-left">
                     <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text'>{carRangeDetails[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${carRangeDetails[0].category}/${carRangeDetails[0].id}/${carRangeDetails[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>
            <figure>
            <img src={carRangeDetails[i].url} width="100%"/>
          <figcaption className='text-right standard-section-margin'>
          </figcaption>
          
        
            </figure>
        
          </div>
            
         )
    
        }

   
}


if(suvRange.length){ 

    if(suvRange.length > 1){
        
    suvRangeDetails = suvRange.map((suvRangeDetails, i) => 
    
   
    <div key={i} className="col-12 col-md-4 mx-auto standard-margin-bottom">
                 <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text'>{suvRangeDetails[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${suvRangeDetails[0].category}/${suvRangeDetails[0].id}/${suvRangeDetails[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>
        <figure>
        <img src={suvRangeDetails[0].url} width="100%"/>
      <figcaption className='text-right standard-section-margin'>
    
      </figcaption>
      
    
        </figure>
    
      </div>
     

        
     )

    }


    if(suvRange.length === 1){
                
        suvRangeDetails = suvRange.map((suvRangeDetails, i) => 
     
       
    
        <div key={i} className="col-8 mx-auto standard-margin-bottom text-left">
                   <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text'>{suvRangeDetails[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${suvRangeDetails[0].category}/${suvRangeDetails[0].id}/${suvRangeDetails[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>
        <figure>
        <img src={suvRangeDetails[0].url} width="100%"/>
      <figcaption className='text-right standard-section-margin'>
     
      </figcaption>
      
    
        </figure>
        
          </div>
       
            
         )
    
        }
     
   
}


if(vanRange.length){ 

    if(vanRange.length > 1){
        
    vanRangeDetails = vanRange.map((vanRangeDetails, i) => 
    
   
    <div key={i} className="col-12 col-md-4 mx-auto standard-margin-bottom">
        <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text'>{vanRangeDetails[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${vanRangeDetails[0].category}/${vanRangeDetails[0].id}/${vanRangeDetails[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>
   
        <figure>
        <img src={vanRangeDetails[0].url} width="100%"/>
      <figcaption className='text-right standard-section-margin'>
     
    
      </figcaption>
      
    
        </figure>
    
      </div>
     

        
     )

    }


    if(vanRange.length === 1){
                
        vanRangeDetails = vanRange.map((vanRangeDetails, i) => 
     
       
    
        <div key={i} className="col-8 mx-auto standard-margin-bottom text-left">
                   <div className="row">
            <div className='col-8'>
            <h5 className='ford-font ford-royal-blue-text'>{vanRangeDetails[0].marketingCopy} </h5>
            </div>
            <div className='col-4 text-right'>
            <a className='btn btn-ford-blue ford-royal-blue-bg standard-margin-bottom' href={`/brands/variants/${vanRangeDetails[0].category}/${vanRangeDetails[0].id}/${vanRangeDetails[0].variantName}`}>
       Overview
       
      </a>
                </div>
        </div>
        <figure>
        <img src={vanRangeDetails[0].url} width="100%"/>
      <figcaption className='text-right standard-section-margin'>
     
   
      </figcaption>
      
    
        </figure>
        
          </div>
       
            
         )
    
        }
     
   
}




    return ( 

        

        <>

        {/*--------------------Mobile-Navigation--------------------------*/}
            <nav className="bgBoxShadow d-block d-md-none fixed-top white-bg">
                        <div className='menu'>
                            <Link className="navbar-brand" exact to="/"><img src={fordlogo} width="40%"/></Link>
                            <div className='menu-icon'>
                                    <MenuOutlined className='menuClick' onClick={showMenu}/>
                            </div>

                        </div>


                        <div className={isopen ? 'slider active overflow-allow' : "slider"}>

                            <div className='closed'>
                                    <Close className="close" id="close" />
                            </div>
                            <ul className='ford-font ford-royal-blue-text navigation-clearance-democratic-banner full-width'>
                                  
                                <li>
                                <a className='noDecor2' data-bs-toggle="collapse" href="#cars" id="car-button" role="button" aria-expanded="false" aria-controls="collapseExample">CARS</a>
                                <div class="collapse" id="cars">
                                  <div class="card card-body border-off-mob">
                                  {carRangeDetails}
                                     </div>
                               </div>
                                </li>
                                    <hr/>
                                <li>
                                <a className='noDecor2' data-bs-toggle="collapse" href="#suvs" id="suv-button"  role="button" aria-expanded="false" aria-controls="collapseExample">SUVs</a>
                                <div class="collapse" id="suvs">
                                  <div class="card card-body border-off-mob">
                                  {suvRangeDetails}
                                     </div>
                               </div>
            
                                </li>
                                    <hr/>
                                <li>
                                <a className='noDecor2' data-bs-toggle="collapse" href="#vans" id="van-button"  role="button" aria-expanded="false" aria-controls="collapseExample">VANS</a>
                                <div class="collapse" id="vans">
                                  <div class="card card-body border-off-mob">
                                  {vanRangeDetails}
                                     </div>
                               </div>
            
                                </li>
                                    <hr/>
                                <li>
                                <Link to="#" className='noDecor2' onClick={showMenu}>ASK CHARIS</Link>
                                </li>
                                    <hr/>
                                <li>
                                <Link to="/aftersales" className='noDecor2' onClick={showMenu}>AFTERSALES</Link>
                                </li>
                                    <hr/>
                                <li>
                                <Link to="/finance" className='noDecor2' onClick={showMenu}>FINANCE</Link>
                                </li>
                                    <hr/>
                                    <li>
                                <Link to="/trade-in" className='noDecor2' onClick={showMenu}>TRADE-IN</Link>
                                </li>
                                    <hr/>
                                <li>
                                <Link to="/Contact" className='noDecor2' onClick={showMenu}>CONTACT US </Link>
                                </li>
                                    <hr/>
                                <li>
                                <Link to="/latest-news" className='noDecor2' onClick={showMenu}>LATEST NEWS</Link>
                                </li>
                                    <hr/>
                                    <li>
                                <Link to="/aftersales#register" className='noDecor2' onClick={showMenu}> <button className='btn btn-ford-blue ford-font'>Book a service</button></Link>
                                </li>
                            </ul>

                           
                        </div>
                </nav>






        {/*-----------------------Desktop-Navigation---------------------------*/}
    
            <nav className="navbar navbar-expand-lg bgBoxShadow d-none d-md-block fixed-top white-bg">
                    <div className="container-fluid">
                        <Link className="navbar-brand" exact to="/"><img src={fordlogo} width="140px"/></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navHome" aria-controls="navHome" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navHome">
                        <ul className="col-12 navbar-nav nav-tabs text-center" id="myTab" role="tablist">
                            <li className="nav-item nav-menu-width">
                            <button class="nav-link nav-font-size full-width desktopMenu" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={openDesktopMenu}>ALL VEHICLES</button>
    </li>
                            <li className="nav-item nav-menu-width">
                            <button class="nav-link nav-font-size full-width desktopMenu" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={openDesktopMenu}>  CARS </button>
     </li>
                            <li className="nav-item nav-menu-width">
                            <button class="nav-link  nav-font-size full-width desktopMenu" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={openDesktopMenu}>SUVs</button>
       </li>
                            <li className="nav-item nav-menu-width">
                            <button class="nav-link  nav-font-size full-width desktopMenu" id="vans-tab" data-bs-toggle="tab" data-bs-target="#van" type="button" role="tab" aria-controls="vans" aria-selected="false" onClick={openDesktopMenu}>VANS</button>
     </li>
                            <li className="nav-item nav-menu-width">
                            <Link className="nav-link nav-font-size desktopMenu" to="#" onClick={closeDesktopMenu}>ASK CHARIS</Link>
                            </li>
                            <li className="nav-item nav-menu-width">
                            <Link className="nav-link nav-font-size desktopMenu" to="/aftersales" onClick={closeDesktopMenu}>AFTERSALES</Link>
                            </li>
                            <li className="nav-item nav-menu-width">
                            <Link className="nav-link nav-font-size desktopMenu" to="/trade-in" onClick={closeDesktopMenu}>TRADE-IN</Link>
                            </li>
                            <li className="nav-item nav-menu-width">
                            <Link className="nav-link nav-font-size desktopMenu" to="/finance" onClick={closeDesktopMenu}>FINANCE</Link>
                            </li>
                            <li className="nav-item nav-menu-width">
                            <Link className="nav-link nav-font-size desktopMenu" to="/latest-news" onClick={closeDesktopMenu}>LATEST NEWS</Link>
                            </li>
                            <li className="nav-item nav-menu-width-alt">
                            
                        <Link className="nav-link nav-font-size text-right" to="/aftersales">
                            <button className='btn btn-ford-blue'>Book a service</button>
                            </Link>
                            </li>
                        </ul>

                        </div>
                    </div>
                    <div className="tab-content car-navigation-panel" id="myTabContent">


                    <div className="tab-pane show fade white-bg" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row">
                            <div className="col-4"> 
                            <h4 className="ford-font ford-royal-blue-text standard-text-padding standard-section-margin"> Available Range </h4>
                  
                            </div>
                            <div className='col-8 text-right standard-text-padding'>
                                <img className="pointer close-menu" onClick={closeDesktopMenu} src={cancel} width="2%"/> 
                            </div>
                        </div>
                     <div class="d-flex align-items-start">

                       
  <div class="nav flex-column nav-pills me-3 col-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <button class="nav-link active navigation-panel-style" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Cars</button>
    <button class="nav-link navigation-panel-style" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">SUVs</button>
    <button class="nav-link navigation-panel-style" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Vans & Trucks</button>
     </div>
  <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
    <div className="row">
        {carRangeDetails}
        </div>
        </div>
    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
    <div className="row">
        {suvRangeDetails}
        </div>
        
        </div>
    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
        
    <div className="row">
        {vanRangeDetails}
        </div>
        
        </div>
    </div>
</div>
                        
                        
                        
                        </div>


                    <div className="tab-pane fade white-bg" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="row">
                            <div className="col-4"> 
                            <h4 className="ford-font ford-royal-blue-text standard-text-padding standard-section-margin"> Available Range </h4>
                  
                            </div>
                            <div className='col-8 text-right standard-text-padding'>
                                <img className="pointer close-menu" onClick={closeDesktopMenu} src={cancel} width="2%"/> 
                            </div>
                        </div>
                                         <div className="row">
        {carRangeDetails}
        </div>
                        
                        
                        </div>
                    <div className="tab-pane fade white-bg" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="row">
                            <div className="col-4"> 
                            <h4 className="ford-font ford-royal-blue-text standard-text-padding standard-section-margin"> Available Range </h4>
                  
                            </div>
                            <div className='col-8 text-right standard-text-padding'>
                                <img className="pointer close-menu" onClick={closeDesktopMenu} src={cancel} width="2%"/> 
                            </div>
                        </div>
                    <div className="row">
        {suvRangeDetails}
        </div>
                        
                        
                        </div>
                    <div className="tab-pane fade white-bg" id="van" role="tabpanel" aria-labelledby="vans-tab">
                        
                    <div className="row">
                            <div className="col-4"> 
                            <h4 className="ford-font ford-royal-blue-text standard-text-padding standard-section-margin"> Available Range </h4>
                  
                            </div>
                            <div className='col-8 text-right standard-text-padding'>
                                <img className="pointer close-menu" onClick={closeDesktopMenu} src={cancel} width="2%"/> 
                            </div>
                        </div>

                    <div className="row">
        {vanRangeDetails}
        </div>                 
                        </div>
</div> 
                </nav>

                
  
        </>
     );
}
 
export default Navbar;