import React, { useState, useEffect,Component } from 'react';
import moment from 'moment';
import {useParams, Link} from 'react-router-dom';
import blog1 from '../../assets/images/blog1.png';
import '../../component/blog/blog.css';
import ford from '../../assets/images/ford.png'
import Card from 'react-bootstrap/Card';
import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
  
  const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });


const SingleBlog = () => {

    const params = useParams()

    const [blogImage, setBlogImage] = useState("")
    const [dateDetails, setDateDetails] = useState("")
    const [headlineDetails, setHeadlineDetails] = useState("")
    const [bodyDetails, setBodyDetails] = useState("")

    useEffect(() => {   


 
    
        client
        .query({
          query: gql`
           query($id:ID!){
            newsBy(id:$id) {
                date
                categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
                new {
                 featuredImage{
                        mediaItemUrl
                      }
                  newsBody
                  newsHeadline
                }
              }
            } 
          `,
          variables: {id:params.id}
          })
        .then(result => {
           console.log(result.data.newsBy.date)
           let dateFormat = moment(result.data.newsBy.date).format('MMMM Do YYYY')
           console.log(result.data.newsBy.new.newsHeadline)
           console.log(result.data.newsBy.new.newsBody)
           setBlogImage(result.data.newsBy.new.featuredImage.mediaItemUrl)
           setDateDetails(dateFormat)
           setHeadlineDetails(result.data.newsBy.new.newsHeadline)
           setBodyDetails(result.data.newsBy.new.newsBody)

        })

    }, [])
    window.scrollTo(0, 0)

    return(
        <>
            <div className='container navigation-clearance mobile-margin-clearance-banner'>
                <div className='row'>
                    <div className='col-12'>
                        <img src={blogImage} width="100%" alt='single-post'/>
                        </div>
                       
                        <div className='col-12 text-center'>
                            <h1>
                            {headlineDetails}
                            </h1>
                        </div>
                        <div className='col-12 text-center'>
                        <h5> {dateDetails} </h5> 
                        </div>
           
                        <div className='col-12 mt-5 mb-5 blogParagraph'>
                        <p>
                        {bodyDetails}
                        </p>
                         
                        </div>

                        <Link className="ford-blue-text" exact to="/latest-news"> Back to News </Link>
                            
                </div>
            </div>

{/*------Next Article section
            <div className='nextArticle mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6 articleCol' id="rightBorder">
                            <h3>Ford Offer Customers unbeatable discount on the Mustang</h3>
                        </div>
                        <div className='col-12 col-md-6 articleCol'>
                            <h3>Ford Offer Customers unbeatable discount on the Mustang</h3>
                        </div>

                  
                            <div className='col-12 mt-5'>
                                <div className='row'>
                                <Card className="ford-font pOff standard-margin-top-bottom">
      <Card.Img variant="top" src={ford} className="pOff" />
      <Card.Body>
        <Card.Title className="text-center">Ford unveils Edge at the Abuja Motor Fair</Card.Title>
        <Card.Text>
         <p className="text-center ford-font ford-grey-text"> The company promises to delight her numerous customers with special
                                                    promotional offers. The highpoint of the motor fair will be unveiling of 
                                                    a new Ford Variant. EDGE into the Nigeria market.
                                                    </p>
        </Card.Text>
            </Card.Body>
            <div className="col-12 standard-section-margin text-center">
            <Link class="noDecor2" to="#">
            <h5 className="ford-font ford-royal-blue-text noDecor2">Read More </h5>
            </Link>
            </div>
    </Card>

    <Card className="ford-font pOff standard-margin-top-bottom">
      <Card.Img variant="top" src={ford} className="pOff" />
      <Card.Body>
        <Card.Title className="text-center">Ford unveils Edge at the Abuja Motor Fair</Card.Title>
        <Card.Text>
         <p className="text-center ford-font ford-grey-text"> The company promises to delight her numerous customers with special
                                                    promotional offers. The highpoint of the motor fair will be unveiling of 
                                                    a new Ford Variant. EDGE into the Nigeria market.
                                                    </p>
        </Card.Text>
            </Card.Body>
            <div className="col-12 standard-section-margin text-center">
            <Link class="noDecor2" to="#">
            <h5 className="ford-font ford-royal-blue-text noDecor2">Read More </h5>
            </Link>
            </div>
    </Card>


    <Card className="ford-font pOff standard-margin-top-bottom">
      <Card.Img variant="top" src={ford} className="pOff" />
      <Card.Body>
        <Card.Title className="text-center">Ford unveils Edge at the Abuja Motor Fair</Card.Title>
        <Card.Text>
         <p className="text-center ford-font ford-grey-text"> The company promises to delight her numerous customers with special
                                                    promotional offers. The highpoint of the motor fair will be unveiling of 
                                                    a new Ford Variant. EDGE into the Nigeria market.
                                                    </p>
        </Card.Text>
            </Card.Body>
            <div className="col-12 standard-section-margin text-center">
            <Link class="noDecor2" to="#">
            <h5 className="ford-font ford-royal-blue-text noDecor2">Read More </h5>
            </Link>
            </div>
    </Card>



    <Card className="ford-font pOff standard-margin-top-bottom">
      <Card.Img variant="top" src={ford} className="pOff" />
      <Card.Body>
        <Card.Title className="text-center">Ford unveils Edge at the Abuja Motor Fair</Card.Title>
        <Card.Text>
         <p className="text-center ford-font ford-grey-text"> The company promises to delight her numerous customers with special
                                                    promotional offers. The highpoint of the motor fair will be unveiling of 
                                                    a new Ford Variant. EDGE into the Nigeria market.
                                                    </p>
        </Card.Text>
            </Card.Body>
            <div className="col-12 standard-section-margin text-center">
            <Link class="noDecor2" to="#">
            <h5 className="ford-font ford-royal-blue-text noDecor2">Read More </h5>
            </Link>
            </div>
    </Card>

                                </div>
                            </div>
                    </div>
                    
                </div>
                
            </div>
        --------------------*/}
        </>

    )
}

export default SingleBlog;