import React, { useState, useEffect, Component } from 'react';

const sitemap = () => {



    window.scrollTo(0, 0)


  

    return(
        
        <>
        <div className="forBlog navigation-clearance mobile-margin-clearance-banner">
                <h5 className='mOff'> Site Map 
 </h5>
            </div>
    
     
     <div className='row ford-font full-width navigation-clearance-democratic-banner mROff'>

     <div className="col-12 standard-margin-bottom text-center">


<table> 
<tr>
    <th>Page</th>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz" target="_blank"> HomePage  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/aftersales" target="_blank"> Aftersales </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/trade-in" target="_blank"> Trade-in  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/finance" target="_blank"> Finance  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/contact" target="_blank"> Contact  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/ask-charis" target="_blank"> Ask Charis  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/test-drive/FIGO" target="_blank"> test-drive  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/Cars/cG9zdDo0MjA=/FIGO" target="_blank"> Figo  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/SUVs/cG9zdDo0MTc=/ECOSPORT" target="_blank"> Ecosport  </a>

    </td>
  </tr>

  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/SUVs/cG9zdDo0MTQ=/Everest" target="_blank"> Everest  </a>

    </td>
  </tr>

  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/SUVs/cG9zdDo0MTg=/EXPLORER" target="_blank"> Explorer  </a>

    </td>
  </tr>

  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/SUVs/cG9zdDo0MTU=/BRONCO" target="_blank"> Bronco  </a>

    </td>
  </tr>

  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/SUVs/cG9zdDo0MTY=/TERRITORY" target="_blank"> Territory  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/Vans&Trucks/cG9zdDo1Mzk=/F150" target="_blank"> F150  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz/brands/variants/Vans&Trucks/cG9zdDo0MTk=/RANGER" target="_blank"> Ranger  </a>

    </td>
  </tr>
  <tr>
    <td>
        
        <a href="cg-ford.inspireleadership.biz" target="_blank"> Edge   </a>

    </td>
  </tr>
  
</table>

</div>
       
     </div>

        </>
        
    )
}

export default sitemap;