import React, { useState, useEffect, Component } from 'react';
import { useForm } from "react-hook-form";
import '../blog/blog.css';
import timetrack from '../../assets/images/k.jpg';
import tradein from '../../assets/images/ecosport.jpg';
import engine from '../../assets/images/engine.png';
import ecosportMob from '../../assets/images/ecosport-mob.jpg';
import {Link} from 'react-scroll';
import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });
  

    



const TradeIn = () => {

  let { register, handleSubmit, watch, formState: { errors } } = useForm()
  let onSubmit = async function (data){
    
        
    
        
      let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/trade-in',{ 
          method: "POST",
          body:JSON.stringify(data),
          headers:{
            'Content-Type':'application/JSON',
            'Accept': 'application/JSON'
          },
          mode:'cors' 
        })
      
        .then(response => response.json())
        .then(response => { 
            alert(response)
    
        })
       
  
  
  };


    window.scrollTo(0, 0)

    const [tradeInBanner, setTradeInBanner] = useState("");
    const [tradeInBannerMobile, setTradeInBannerMobile] = useState("");
    const [secondBanner, setSecondBanner] = useState("");
    const [thirdBanner, setThirdBanner] = useState("");

    useEffect(() => {
     
        client
      .query({
        query: gql`
        query{
            pages {
                edges {
                  node {
                    tradeIn {
                      tradeInBanner{
                        mediaItemUrl
                      }
                      tradeInMobile{
                        mediaItemUrl
                      }
                      secondImage{
                        mediaItemUrl
                      }
                      thirdImage{
                    mediaItemUrl
                      }
                    
                    }
                  }
                }
              }
        }
    
        `,
      })
      .then(result => {
        console.log(result.data.pages.edges)
        setTradeInBanner(result.data.pages.edges[2].node.tradeIn.tradeInBanner.mediaItemUrl)
        setTradeInBannerMobile(result.data.pages.edges[2].node.tradeIn.tradeInMobile.mediaItemUrl)
        setSecondBanner(result.data.pages.edges[2].node.tradeIn.secondImage.mediaItemUrl)
        setThirdBanner(result.data.pages.edges[2].node.tradeIn.thirdImage.mediaItemUrl)
            });
   
     }, [])

    return (
        <>
            <div className="forBlog navigation-clearance mobile-margin-clearance-banner">
                <h5 className="mBOff ford-font">Ford Vehicle Swap</h5>
            </div>
            <div className='row full-width mOff'>
                <div className='col-12 pOff d-none d-sm-block'>
                    <img src={tradeInBanner}  alt="" width="100%"/>
                </div>

                <div className='col-12 pOff d-block d-sm-none'>
                    <img src={tradeInBannerMobile}  alt="" width="100%"/>
                </div>
                <div className='col-12 track text-center p-5'>
                    <p className='ford-text ford-grey-text'>Finance your next Ford vehicle purchase through a trade-in.</p>
                    <button className='btn btn-ford-blue ford-font'><Link to="application" spy={true} smooth={true}>Apply Now</Link></button>
                </div>
            </div>


                <div className='row mb-5 blogContent readMore container-fluid mt-5 mROff'>
                    <div className='col-12 col-md-6'>
                        <img src={secondBanner} alt="blogimage" width="100%"/>
                    </div>

                    <div className='col-12 col-md-6 p-4'>
                        <h2 className='ford-font ford-blue-text'>Mileage is a key factor we  consider before approving trade-in finance</h2>  
                        <p className='ford-font ford-grey-text light-font-weight'>Car's mileage is the total distance travelled by the car since its manufacture. The number is displayed on a vehicle's odometer and is one of the most important factors that determines the price of a pre-owned car. It is an indication of the amount of wear and tear the car has sustained over time.
                        </p>

                    </div>
                </div>

                <div className='track text-center p-5'>
                    
                </div>

                <div className='row mb-5 blogContent readMore container-fluid mt-5 mROff'>

                    <div className='col-12 col-md-6 p-4'>
                        <h2 className='ford-font ford-blue-text'>Health history is more critical to the trade-in finance process for your Ford car</h2>  
                        <p className='ford-font ford-grey-text light-font-weight'>Your vehicle health history is the record of all maintenance activities around your ownership of our Ford vehicles. This information helps us certify the re-use value for our pre-owned sales. The more detailed your vehicle history, the more value you can protect within your equity.
                        </p>

                    </div>

                    <div className='col-12 col-md-6'>
                        <img src={thirdBanner} alt="blogimage" width="100%"/>
                    </div>

                </div>

                <div className='track text-center p-5' id="application">
                    <p className='ford-font ford-grey-text'>Apply for a car valuation with your vehicle health history and authentic mileage below. We will let you know if your vehicle passes for trade-in finance. 
                    </p>
                </div>

                <div className='p-5 text-center'>
                    <form className='col-12 col-md-6 mx-auto' onSubmit={handleSubmit(onSubmit)}>

                            <input className='form-control' type="text" placeholder='First name'  {...register("firstname", {required:true})}/>

                            <input className='form-control' type="text" placeholder='Surname' {...register("lastname", {required:true})}/>

                            <input className='form-control' type="email" placeholder='Email' {...register("email", {required:true})}/>

                            <input className='form-control' type="text" placeholder='Mobile No' {...register("mobileno", {required:true})}/>

                            <input className='form-control' type="text" placeholder='Mileage' {...register("mileage", {required:true})}/>

                            <select name="variant" className='form-control'  {...register("variant", {required:true})}>
                        <option value="none">Select a variant</option>
                        <option value="figo">Figo</option>
                        <option value="focus">Focus</option>
                        <option value="fusion">Fusion</option>
                        <option value="ecosport">Ecosport</option>
                        <option value="escape">Escape</option>
                        <option value="ranger">Ranger</option>
                        <option value="f-150">F-150</option>
                        <option value="explorer">Explorer</option>
                        <option value="everest">Everest</option>
                    </select>

                    <select className='form-control ford-royal-blue-font' {...register("model", {required:true})}>

<option value="none">Select a Model Year </option>
<option value="2022"> 2022 </option>
<option value="2021"> 2021 </option>
<option value="2020"> 2020 </option>
<option value="2019"> 2019 </option>
<option value="2018"> 2018 </option>
<option value="2017"> 2017 </option>
<option value="2016"> 2016 </option>
<option value="2015"> 2015 </option>
<option value="2014"> 2014 </option>
<option value="2013"> 2013 </option>
<option value="2012"> 2012 </option>
<option value="2011"> 2011 </option>
<option value="2010"> 2010 </option>
<option value="2009"> 2009 </option>
<option value="2008"> 2008 </option>

</select>
                            <div className='col-12 text-right'>
                            <button className='btn btn-ford-blue ford-font'> Apply for valuation </button>
                            </div>
                    </form>
                </div>
        </>
    )
}


export default TradeIn;