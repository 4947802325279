import React, { useState, useEffect, Component } from 'react';

const Privacy = () => {



    window.scrollTo(0, 0)


  

    return(
        
        <>
        <div className="forBlog navigation-clearance mobile-margin-clearance-banner">
                <h5 className='mOff'>Privacy Statement </h5>
            </div>
    
     
     <div className='row ford-font full-width navigation-clearance-democratic-banner mROff'>

     <div className="col-12 standard-margin-bottom text-center">

<p className="ford-royal-blue-text"> 
Coscharis Motors Customer Privacy Policy covers the collection, use, and disclosure of personal information that may be collected by Coscharis Motors anytime you interact with Coscharis Motors, such as when you visit our Ford Nigeria website, when you purchase Ford products and services from Coscharis Motors, or when you call our sales or support associates.
 Please take a moment to read the following to learn more about our information practices, including what type of information is gathered, how the information is used and for what purposes, to whom we disclose the information, and how we safeguard your personal information. Your privacy is a priority at Coscharis Motors, and we go to great lengths to protect it.
</p>

</div>



<h4 className="ford-royal-blue-text"> Why we collect personal information </h4>
<p className="ford-royal-blue-text">
By Personal information we mean information about you that is personally identifiable like your name, address, email address, and or phone number that is not otherwise publicly available. We collect your personal information because it helps us deliver a superior level of customer satisfaction. 
It enables us to give you convenient access to our products and services and focus on categories of greatest interest to you. In addition, your personal information helps us keep you posted on the latest product announcements, special offers, and events that you might like to hear about.If you do not want Coscharis Motors to keep you up to date with news and the latest information on Ford products and services please contact us.
</p>


<h4 className="ford-royal-blue-text">  What information we collect and how we may use it</h4>
<p className="ford-royal-blue-text">

There are a number of situations in which your personal information may help us give you better products. For example:• We may ask for your personal information when you're discussing a service issue on the phone with an advisor, downloading content from us, participating in an online survey, or purchasing a product or service.• When you interact with Coscharis Motors we may collect personal information relevant to the situation, such as your name, mailing address, phone number, email address, and contact preferences; and information about the Ford products you own, such as their chassis/serial numbers and information relating to a service issue.• We also collect information for market research purposes — such as your occupation and where you use our product — to gain a better understanding of our customers and thus provide more valuable service.• We collect information regarding customer activities on our websites. This helps us to determine how best to provide useful information to customers and to understand which parts of our websites, products, and Internet services are of most interest to them.• We may use personal information to provide products that you have requested as well as for auditing, research, and analysis to improve Coscharis Motors products/services.
</p>


<h4 className="ford-royal-blue-text">  Your Coscharis Motors ID and related information </h4>
<p className="ford-royal-blue-text">       
 The Ford Nigeria website, as well as Ford services, allows you to create a "Coscharis Motors ID" based on your personal information. This convenient service saves you time and allows for easier use of our web services. Here's how it works: You create a personal profile — providing your personal information — and choose a password and password hint (such as the month and day of your birth) for security. The system saves your information and assigns you a personal Coscharis Motors ID — in many cases simply your email address, because it's unique and easy to remember. The next time you order from Coscharis Motors’ Stores or register a new Ford product, all you need to do is enter your Coscharis Motors ID and password; the system looks up the information it needs to assist you. In addition, if you update the information associated with your Coscharis Motors ID, it will be available for all your transactions with Coscharis Motors globally.
</p>

<h4 className="ford-royal-blue-text">  Publicly displayed information is public </h4>
<p className="ford-royal-blue-text">       

If you use any forum like chat room on a Coscharis Motors website you should be aware that any information you share is visible to other users. Personally identifiable information you submit to one of these forums can be read, collected, or used by other individuals to send you unsolicited messages. Coscharis Motors is not responsible for the personally identifiable information you choose to submit in these forums. For example, if you choose to make information, which was previously non-public, available by enabling certain user features, Coscharis Motors will collect that information from your interaction and the information will become publicly available.
</p>

<h4 className="ford-royal-blue-text">     When we disclose your information </h4>
<p className="ford-royal-blue-text">       


Coscharis Motors takes your privacy very seriously. Coscharis Motors does not sell or rent your contact information to other marketers.  </p>

<h4 className="ford-royal-blue-text"> Within the Coscharis Motors </h4>
<p className="ford-royal-blue-text">       
To help us provide superior service, your personal information may be shared with legal entities within the Coscharis Motors globally who will take steps to safeguard it in accordance with Coscharis Motors’ privacy policy.
</p>

<h4 className="ford-royal-blue-text">  With our service providers, vendors, and strategic partners </h4>
<p className="ford-royal-blue-text">       

There are also times when it may be advantageous for Coscharis Motors to make certain personal information about you available to companies that Coscharis Motors has a strategic relationship with to provide products and services to you on our behalf. These companies may help us process information, extend credit, fulfil customer orders, deliver products to you, manage and enhance customer data, provide customer service, assess your interest in our products and services, or conduct customer research or satisfaction surveys. These companies are also obligated to protect your personal information in accordance with Coscharis Motors’ policies, except if we inform you otherwise at the time of collection. For example, when you purchase and activate services via Coscharis Motors, you authorize Coscharis Motors and its partners to exchange the information that you provide during the activation process to carry out the service. If you are approved for service, your account will be governed by its partners' respective privacy policies. Without such information being made available, it would be difficult for you to purchase products, have products delivered to you, receive customer service, provide us feedback to improve our products and services, or access certain services, offers, and content on the Ford Nigeria website.At times we may be required by law or litigation to disclose your personal information. We may also disclose information about you if we determine that for national security, law enforcement, or other issues of public importance, disclosure is necessary.
</p>

<h4 className="ford-royal-blue-text">  How we protect your personal information </h4>
<p className="ford-royal-blue-text">             
Coscharis Motors takes precautions — including administrative and technical measures — to safeguard your personal information against loss, theft, and misuse, as well as unauthorized access, disclosure, alteration, and destruction. The Ford Nigeria Online Stores/Services use Secure Sockets Layer (SSL) encryption on all web pages where personal information is required. To make purchases from the Ford Nigeria Online Stores/Services, you must use an SSL-enabled browser such as Chrome, Firefox, Netscape or Internet Explorer. Doing so protects the confidentiality of your personal information while it's transmitted over the Internet.You can help us by also taking precautions to protect your personal data when you are on the Internet. Change your passwords often using a combination of letters and numbers, and make sure you use a secure web browser like Chrome.        
</p>

<h4 className="ford-royal-blue-text">  Integrity of your personal information </h4>
<p className="ford-royal-blue-text">             

Coscharis Motors has safeguards in place to keep your personal information accurate, complete, and up to date for the purposes for which it is used. Naturally, you always have the right to access and correct the personal information you have provided. You can help us ensure that your contact information and preferences are accurate, complete, and up to date by checking at www.fordnigeria.com/contact-us/. And you can request a copy of your personal information, your product registration history, and your interactions with our sales and support team by contacting us at the email address: info@coscharisgroup.net
</p>


<h4 className="ford-royal-blue-text">  Kids </h4>
<p className="ford-royal-blue-text">             
We do not knowingly collect personal information from children under 13 for marketing purposes, but because some information is collected electronically, it can appear to be the personal information of the Coscharis Motors purchaser of the product, and will be treated as such by this privacy policy. If a child under 13 submits personal information to Coscharis Group and we learn that that personal information is the information of a child under 13, we will attempt to delete the information as soon as possible.
</p>

<h4 className="ford-royal-blue-text"> Cookies and other technologies </h4>
<p className="ford-royal-blue-text">             
As is standard practice on many corporate websites, www.fordnigeria.com uses "cookies" and other technologies to help us understand which parts of our websites are the most popular, where our visitors are going, and how much time they spend there. We also use cookies and other technologies to make sure that our online advertising is bringing customers to our products and services. We use cookies and other technologies to study traffic patterns on our website, to make it even more rewarding as well as to study the effectiveness of our customer communications. And we use cookies to customize your experience and provide greater convenience each time you interact with us. For example, knowing your first name lets us welcome you with a greeting the next time you visit the Ford Nigeria Stores/Services. Information such as your country and language — and if you're an educator, your school — helps us provide a more useful online shopping experience. And your contact information, product serial numbers, and information about your vehicle helps us register your products and provide you with customer service.Please note that certain features of the Ford Nigeria website will not be available once cookies are disabled.
As is true of most websites, we gather certain information automatically and store it in log files. This information includes Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP) and date/time stamp. We use this information, which does not identify individual users, to analyze trends, to administer the site, to track users' movements around the site and to gather demographic information about our user base as a whole. Coscharis Motors will not use the information collected to market directly to that person.In some of our email messages we use a "click-through URL" linked to content on the Ford Nigera website. When customers click one of these URLs, they pass through our web server before arriving at the destination web page. We track this click-through data to help us determine interest in particular topics and measure the effectiveness of our customer communications. If you prefer not to be tracked simply avoid clicking text or graphic links in the email.In addition we use pixel tags — tiny graphic images — to tell us what parts of our website customers have visited or to measure the effectiveness of searches customers perform on our site.Pixel tags also enable us to send email messages in a format customers can read. And they tell us whether emails have been opened to ensure that we're sending only messages that are of interest to our customers. We may use this information to reduce or eliminate messages sent to a customer. We store all of this information in a secure database located in Lekki, Lagos, in the Federal Republic of Nigeria.
</p>


<h4 className="ford-royal-blue-text">  Our company wide commitment to your privacy </h4>
<p className="ford-royal-blue-text">             
As we said, Coscharis Motors takes protecting your privacy very seriously. To make sure your personal information is secure; we communicate these guidelines to Coscharis Motors employees and strictly enforce privacy safeguards within the company.
</p>
<h4 className="ford-royal-blue-text">  International users </h4>
<p className="ford-royal-blue-text">             

www.fordnigeria.com has links to the sites of other companies. Coscharis Motors is not responsible for their privacy practices. We encourage you to learn about the privacy policies of those companies.
</p>

<h4 className="ford-royal-blue-text">      Privacy Questions </h4>
<p className="ford-royal-blue-text">             
If you have questions or concerns about Coscharis Motors Customer Privacy Policy or data processing, please use this email address to contact us: info@coscharisgroup.net. The Coscharis Group Data Controller will respond to your inquiry within 30 days.The information contained in this web site is subject to change without notice. 
Copyright © 2022 Coscharis Motors Plc. All rights reserved. Coscharis Motors Plc., Km 32 Lekki Epe Express Way, Awoyaya, Lagos State, Nigeria
</p>


 

       

     </div>

        </>
        
    )
}

export default Privacy;