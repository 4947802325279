import React, { useState, useEffect, Component } from 'react';
import { useForm } from "react-hook-form";
import {useParams, Link} from 'react-router-dom';
import '../testdrive/testdrive.css';
import figo from '../../assets/images/testdrive1.png'
import ecosport from '../../assets/images/testdrive2.png'
import edge from '../../assets/images/testdrive3.png'
import explorer from '../../assets/images/testdrive4.png'
import mustang from '../../assets/images/testdrive5.png'
import ranger from '../../assets/images/testdrive6.png'



const TestDrive = () =>{
    const params = useParams()

    const [figoCheckbox, setFigoCheckbox] = useState(false);
    const [edgeCheckbox, setEdgeCheckbox] = useState(false);
    const [explorerCheckbox, setExplorerCheckbox] = useState(false);
    const [ecosportCheckbox, setEcosportCheckbox] = useState(false);
    const [everestCheckbox, setEverestCheckbox] = useState(false);
    const [broncoCheckbox, setBroncoCheckbox] = useState(false);
    const [territoryCheckbox, setTerritoryCheckbox] = useState(false);
    const [rangerCheckbox, setRangerCheckbox] = useState(false);
    const [f150Checkbox, setF150Checkbox] = useState(false);


    let { register, handleSubmit, watch, formState: { errors } } = useForm()
    let onSubmit = async function (data){
      
        
          
      
          
        let submitTestDriveRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/test-drive',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert("Request For Testdrive Submitted")
      
          })
         
    
    
    };
    
  useEffect(() => {     
    const getVehicleFromParam = (variantName) => {
        console.log(params.variantName)
        switch (variantName) {
          case "FIGO":
           return  setFigoCheckbox(true)
          case "EDGE":
           return  setEdgeCheckbox(true)
          case "EXPLORER":
           return  setExplorerCheckbox(true)
          case "ECOSPORT":
           return  setEcosportCheckbox(true)
          case "EVEREST":
           return  setEverestCheckbox(true)
          case "BRONCO":
           return  setBroncoCheckbox(true)
          case "TERRITORY":
           return  setTerritoryCheckbox(true)
          case "RANGER":
           return  setRangerCheckbox(true)
          case "F150":
           return  setF150Checkbox(true)
          
      };
    
    }


getVehicleFromParam(params.variantName)
}, [])






    window.scrollTo(0, 0)

    return (
        <>
            <div className='track text-center p-5 testDrive'>
                    <h5 className='ford-font ford-blue-text light-font-weight'> REQUEST A TEST DRIVE </h5>
            </div>

            <div className='testDrive1 container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 testDriveCol1 p-3'>
                        <h4 className='ford-font ford-blue-text light-font-weight'>1 SELECT A VEHICLE</h4>
                        {/*--------Row-one---------*/}
                        <div className='row'>
                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="figo" id="inlineRadio1" value="Figo"  checked={figoCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={figo} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">FIGO</label>
                                        </div>
                                </div>
                            </div>

                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="ecosport" id="inlineRadio1" value="Ecosport"  checked={ecosportCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={ecosport} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">ECOSPORT</label>
                                        </div>
                                </div>
                            </div>


                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="edge" id="inlineRadio1" value="Edge"  checked={edgeCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={edge} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">EDGE</label>
                                        </div>
                                </div>
                            </div>
                        </div>


                        {/*-----------Row---two----------------*/}



                        <div className='row'>
                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="explorer" id="inlineRadio1" value="Explorer" checked={explorerCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={explorer} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">EXPLORER</label>
                                        </div>
                                </div>
                            </div>

                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="everest" id="inlineRadio1" value="Everest" checked={everestCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={mustang} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">Everest</label>
                                        </div>
                                </div>
                            </div>

                           
                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="bronco" id="inlineRadio1" value="Bronco" checked={broncoCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={explorer} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">Bronco</label>
                                        </div>
                                </div>
                            </div>
                        </div>

                        {/*-----------Row-three-------------*/}


   

                        <div className='row'>
                            <div className='col-3 form-check'>

                            <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="territory" id="inlineRadio1" value="territory" checked={territoryCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={ranger} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">TERRITORY</label>
                                        </div>
                                </div>
                           </div>
                           
                           <div className='col-3 form-check'>

                                <div className='row text-center'>
                                        <div className='col-12 col text-center'>
                                            <input type="radio" name="ranger" id="inlineRadio1" value="Ranger" checked={rangerCheckbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={ranger} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">RANGER</label>
                                        </div>
                                </div>
                                </div>


                            <div className='col-3 form-check'>
                                <div className='row text-center'>
                                        <div className='col-12 text-center'>
                                            <input type="radio" name="f150" id="inlineRadio1" value="F-150" checked={f150Checkbox}/>
                                        </div>
                                        <div className='col-12'>
                                            <img src={ranger} alt="figo" width="120px"/>
                                        </div>
                                        <div className='col-12'>
                                            <label class="form-check-label" for="inlineRadio1">F-150</label>
                                        </div>
                                </div>
                            </div>
                            </div>
                        </div>

                       
                      
                      


                 

                        

                    <div className='col-12 col-md-6 p-3 detailsCol'>
                        <h4 className='mb-5 ford-font ford-blue-text light-font-weight'>2 YOUR DETAILS</h4>

                        <p className='ford-text ford-grey-text'>
                            Ford Motor Company Limited will be use the information
                            below to verify your selected dealer. We will share your
                            details with that dealer to enable them to fulfil your details
                            with that dealers to enable them to fulful your request using one of the
                            methods below.
                        </p>
                        <p className='ford-text ford-grey-text'>
                            For further information on how we use your data. international data
                            transfers. and your rights please see Full Privacy details.
                        </p>

                        <p className='ford-text ford-grey-text'>
                            To enable us to handle your request in the best way. Please select your next
                            vehicle type. For more information please click here.
                        </p>



                        <div className='p-5 text-center'>
                                <form className='mx-auto testDriveForm' onSubmit={handleSubmit(onSubmit)} >

                                        <input className='form-control' type="text" placeholder='Firstname' {...register("firstname", {required:true})}/>

                                        <input className='form-control' type="text" placeholder='Lastname' {...register("lastname", {required:true})}/>

                                        <input className='form-control' type="email" placeholder='Email'{...register("email", {required:true})} />

                                        <input className='form-control' type="text" placeholder='Mobile No' {...register("mobileno", {required:true})}/>

                                        <select className='form-control' {...register("Conversion", {required:true})}>
                                              <option value="none">When will you purchase</option>
                                              <option value="Immediately">Immediately</option>
                                              <option value="4 weeks">4 weeks </option>
                                              <option value="12 weeks">12 weeks </option>
                                    
                                        </select>
                                        <input className='form-control' type="text" value={params.variantName} placeholder='variantName' {...register("variant", {required:true})} />


                                      

                                        <button className='btn btn-ford-blue ford-font'>Submit Request</button>
                                </form>
                        </div>
                    </div>

                    </div>
                    </div>

            
        </>
    )
}


export default TestDrive;