import React, { Component } from 'react';
import sampleAd from '../../assets/images/sampleAD.jpg';



const democraticBanners = (props) => {
    console.log(props.getAdBanners)

    return(
<>

 <div className="col-12 grey-strip-height ford-grey">

 </div>
 <div className="row ford-font navigation-clearance-democratic-banner full-width mOff text-padding-democratic-banner text-white advert-space-height" style={{backgroundImage:`url(${props.getAdBanners})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    
    
     <div className="col-12 col-md-8">
     <h2 className="light-font-weight ford-blue-text democratic-banner-text width-spec">  {props.getAdCopy} </h2>

<a className="btn btn-outline btn-ford-blue" href={props.getAdLink}> 
            Download Brochure
           </a>

     </div>
  

    </div>
 
    </>
 
 

        
    )
}

export default democraticBanners;