import React, { useState, useEffect, Component } from 'react';
import { Link} from 'react-router-dom';
import '../footer/footer.css'
import youtube from '../../assets/images/youtube.png';
import cglogo from '../../assets/images/cglogo.png';
import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });


const Footer = () => {
  const [variantLinks, setVariantLinks] = useState([])
  const [variantFinal, setVariantFinal] = useState([])
  const [displayLinks, setDisplayLinks] = useState([])
  const [display, setDisplay] = useState([])
let variantLinkPack
let variantArray=[]
let variantDetailPack=[]
let linkArrayDetails



    useEffect(() => {
           
          client
        .query({
          query: gql`
          query{
              fordvariants {
                  edges {
                    node {
                      id
                      title
                      categories{
                        edges{
                          node{
                            id
                            name
                          }
                        }
                      }
                      variants {
                        variantName
                      }
                      id
                    }
                  }
                }
          }
      
          `,
        })
        .then(result => {
      
         
          console.log(result.data)
           variantArray = []
          for( let i=0; i < result.data.fordvariants.edges.length ; i++){
              console.log(result.data.fordvariants.edges[i].node.categories.edges[0].node.name)
              console.log(result.data.fordvariants.edges[i].node.id)
              console.log(result.data.fordvariants.edges[i].node.variants.variantName)
   
         
              variantLinkPack =[{category:result.data.fordvariants.edges[i].node.categories.edges[0].node.name, id: result.data.fordvariants.edges[i].node.id, variantName: result.data.fordvariants.edges[i].node.variants.variantName}]
              variantArray.push(variantLinkPack)
        }

        let footerLinksContainer = document.getElementById('links')
        footerLinksContainer.innerHTML=""
        console.log(variantArray[0][0])
        for(let i=0; i < variantArray.length ; i++ ){
          let variants= document.createElement('a')
          let variantLinkText = document.createElement('li')
          variantLinkText.setAttribute("class", "ford-font") 
          variantLinkText.innerHTML = "Ford" + " " + variantArray[i][0].variantName
          variants.setAttribute("class", "noDecor ford-font")
          variants.setAttribute("href", "/brands/variants/" + variantArray[i][0].category + "/" + variantArray[i][0].id + "/" + variantArray[i][0].variantName)
          variants.append(variantLinkText)
          footerLinksContainer.append(variants)

        }
       

    })

  




    }, [])


    
if(displayLinks){


      
  linkArrayDetails = display.map((linkArrayDetails, i) => 
           
  <a className='noDecor' href={`/brands/variants/${linkArrayDetails.category}/${linkArrayDetails[0].id}/${linkArrayDetails[0].variantName}`}>
  <li> Ford {linkArrayDetails[0].variantName} </li> 
  </a>

      
   )
  
    return (
        <>

        <div className='ford-footer'>
            <div className='blueBg'></div>
                <div className='container standard-padding-top'>
                    <div className='row text-white full-width'>
                        <div className='col-12 col-md-5 FMargin'>
                            <h4 className="ford-font">OUR RANGE OF VEHICLES</h4>
                            <ul className="ford-font light-font-weight" id="links">
                                  
                                    
                                </ul>
                        </div>
                        <div className='col-12 col-md-5 FMargin'>
                            <Link className='noDecor' to="/contact">
                            <h4>Visit Ford Nigeria</h4> 
                            </Link>
                          
                                <ul className="ford-font light-font-weight">
                             
                                    <li>Lagos </li> 
                               
                                    <li>Ibadan </li> 
                               
                                    <li>Abuja </li> 
                          
                                    <li>Port Harcourt</li> 
                             
                                    <li>Uyo</li> 
                              
                                    <li>Kano</li> 
                              
                                    <li>Calabar</li> 
                             
                                    <li>Enugu</li> 
                                </ul>
                        </div>
                        <div className='col-12 col-md-2 FMargin'>
                                <h4>Customer Services</h4> 
                                <ul className="ford-font light-font-weight">
                                    <li>Ask CHARIS</li>
                                    <Link to="/aftersales#register" className="noDecor">
                                    <li>Book a service</li>
                                    </Link>
                                    <Link to="/aftersales#parts" className="noDecor">
                                    <li>Request Parts</li>
                                        </Link> 
                                    <Link to="/contact" className="noDecor">
                                    <li>Contact us</li>
                                    </Link>
                                    <Link to="/latest-news" className="noDecor">
                                    <li>Latest news</li>
                                    </Link>       
                                </ul>
                        </div>
                    </div>
                </div>
                <div className='getUpdates'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-4 mt-5 mb-5'>
                                <span><i class="fa-solid fa-envelope"></i> <a href='#' className='envelop'>Get Updates</a></span> 
                            </div>

                            <div className='col-12 text-center col-md-4 mt-5 mb-5'>
                            <img src={cglogo} width="230px"/>
                             </div>


                            <div className='col-12 col-md-4 followFord mt-5 mb-5'>
                                <span> <small>Follow Ford</small> &nbsp;
                                <a class="noDecor" href="https://www.instagram.com/ford.coscharis/" target="_blank">
                                <i class="fa-brands fa-facebook-square"></i>
                                </a>

                                <a class="noDecor" href="https://web.facebook.com/FordCoscharis" target="_blank">
                                <i class="fa-brands fa-instagram-square"></i>
                                </a>

                            

                                
                                  
                                   
                                  
                                
                                </span>
                                
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-9' id='siteMap'>
                                <span>&copy; 2022 Ford Motor Company</span>&nbsp;&nbsp;
                                <span><a href='/sitemap' target="_blank">Site Map</a></span>&nbsp;&nbsp;                         
                           
                                <span><a href='/terms' target="_blank">Terms & Conditions</a></span>&nbsp;&nbsp;
                                <span><a href='/privacy' target="_blank">Privacy</a></span>&nbsp;&nbsp;
                          
                            </div>

                              <div className='col-12 col-md-3'>
                                <h6 className='ford-font ford-blue-text signature'>Web design and content by iNspire</h6>
                              </div>
                        </div>

                </div>
                </div>
            </div>


        </>
     )
    }
}

export default Footer