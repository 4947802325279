import React, { useState, useEffect, Component } from 'react';
import { useForm } from "react-hook-form";
import $ from 'jquery';



const Parts = (props) => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm()

    const [form, setForm] = React.useState({
        firstname: '',
        lastname: '',
        email: '',
        mobileno: '',
        variant: '',
        model:'',
        part1:'',
        part2:'',
        part3:''
      });

      useEffect(() => {


      

}, [])


const [variant, setVariant] = useState("");

 function handleChange(event) {
 

  }

  async function handleVariantChange(event) {
    let selectedVariant =   $('.variantType').val()

    if(selectedVariant === 'others'){
        $('.variantAlt').fadeIn('slow') 
     }else{
         $('.variantAlt').fadeOut('fast')  
     }


  
  }

    return(
        <>
        <div className="col-12 grey-strip-height ford-grey navigation-clearance mobile-margin-clearance">

</div>
      <div className="row ford-font full-width mROff">  
      <img src={props.partsBanner} className="pOff" />
      </div>
     
     <div className='row ford-font full-width navigation-clearance-democratic-banner mOff'>

        <div className="col-12 col-md-8 mx-auto standard-margin-bottom text-center">
        <h4 className="ford-royal-blue-text"> Access authentic Ford Motor Vehicle spare parts for your independent repairs. </h4>
        <h6 className="ford-royal-blue-text light-font-weight"> Provide basic details about your vehicle and a parts advisor will contact after verification </h6>
        
        </div>

        <div className="col-12">

        <div className='p-5 text-center'>
                    <form className='col-12 col-md-6 mx-auto'>

                            <input className='form-control' type="text" placeholder='Firstname' name="firstname" {...register("firstname", {required:true})}/>

                            <input className='form-control' type="text" placeholder='Surname' name="lastname" {...register("lastname", {required:true})} />

                            <input className='form-control' type="email" placeholder='Email' name="email" {...register("email", {required:true})} />

                            <input className='form-control' type="text" placeholder='Mobile No' name="mobileno" {...register("mobileno", {required:true})} />

                            <select className='form-control ford-royal-blue-font' name="mileage" {...register("mileage", {required:true})}>
                                <option value="none">Select a Mileage Range</option>
                                <option value="1"> 0km  - 20,000km </option>
                                <option value="2"> 20,000km - 40,000km </option>
                                <option value="3"> 40,000km - 60,000km </option>
                                <option value="4"> 60,000km - 80,000km </option>
                            </select>

                          
                            <select className='form-control variantType' name="variant" {...register("variant", {required:true})}>
                                <option value="none">Select a variant</option>
                                <option value="figo">Figo</option>
                                <option value="focus">Focus</option>
                                <option value="fusion">Fusion</option>
                                <option value="ecosport">Ecosport</option>
                                <option value="escape">Escape</option>
                                <option value="ranger">Ranger</option>
                                <option value="f-150">F-150</option>
                                <option value="explorer">Explorer</option>
                                <option value="everest">Everest</option>
                                <option value="others">Others</option>
                            </select>

                            <input className='form-control variantAlt' type="text" placeholder='Type your Variant' name="variantName" {...register("variantName", {required:true})}/>

                            
                            <select className='form-control ford-royal-blue-font' name="model" {...register("model", {required:true})}>
                                <option value="none">Select a Model Year </option>
                                <option value="1"> 2022 </option>
                                <option value="2"> 2021 </option>
                                <option value="3"> 2020 </option>
                                <option value="4"> 2019 </option>
                                <option value="1"> 2018 </option>
                                <option value="2"> 2017 </option>
                                <option value="3"> 2016 </option>
                                <option value="4"> 2015 </option>
                                <option value="1"> 2014 </option>
                                <option value="2"> 2013 </option>
                                <option value="3"> 2012 </option>
                                <option value="4"> 2011 </option>
                                <option value="3"> 2010 </option>
                                <option value="4"> 2009 </option>
                                <option value="1"> 2008 </option>
                                
                            </select>

                            <input className='form-control'  type="text" placeholder='Part Name ' name="part1" {...register("part1", {required:true})}/>
                            <input className='form-control' type="text" placeholder='Part Name ' name="part2"  {...register("part2", {required:true})}/>
                            <input className='form-control' type="text" placeholder='Part Name ' name="part3"  {...register("part3", {required:true})}/>

                
                            <div className='col-12 text-right'>
                            <button id="submit-parts" className='btn btn-ford-blue' > Submit request </button> </div>
                      
                    </form>
                </div>

        </div>

       

     </div>

        </>
        
    )
}

export default Parts;