import './App.css';
import Navbar from './component/navBar/ford_navbar';
import BannerSlider from './component/homepage/bannerSlider';
import Homepage from './component/homepage/homepage';
import Variants from './component/Variant/variant';
import {Routes, Route} from 'react-router-dom';

import Finance from './component/finance/finance';
import FordBlog from './component/blog/blog';
import BlogPage from './component/blog/single';
import Footer from './component/footer/footer';
import TradeIn from './component/Trade-in/tradein';
import Aftersale from './component/aftersales/aftersales';
import TestDrive from './component/testdrive/testdrive';
import Contact from './component/contact/contact';
import Privacy from './component/privacy';
import Tc from './component/tc';
import Sitemap from './component/sitemap';

import $ from 'jquery';
import{InMemoryCache,
ApolloClient,
ApolloProvider
} from '@apollo/client'

const client = new ApolloClient({
  cache:  new InMemoryCache(),
  uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
  fetchOptions: {
    mode: 'no-cors',
  }
});

function App() {
  return (
    <div>
      <header>
          <Navbar/>
      </header>
      <ApolloProvider client={client}>
      <Routes>
            <Route exact path="/" element={<Homepage/>}/>
            <Route path="/latest-news" element={<FordBlog/>}/>
            <Route path="/finance" element={<Finance/>}/>
            <Route path="/trade-in" element={<TradeIn/>}/>
            <Route path="/test-drive/:variantName" element={<TestDrive/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/aftersales' element={<Aftersale/>}/>
            <Route path='/brands/variants/:category/:id/:variantName' element={<Variants/>}/>
            <Route path='/blog/news/:id/Blog/News' element={<BlogPage/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/sitemap" element={<Sitemap/>}/>
            <Route path="/terms" element={<Tc/>}/>
      </Routes>
      </ApolloProvider>


      <Footer/>
    </div>
  );
}

export default App;
