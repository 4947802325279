import React, { useState, useEffect, Component } from 'react';
import { useForm } from "react-hook-form";
//import registerBanner from '../../assets/images/register-banner.jpg';
import $ from 'jquery';



const Register = (props) => {


let { register, handleSubmit, watch, formState: { errors } } = useForm()
let onSubmit = async function (data){
  
      
  
      
    let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/registration',{ 
        method: "POST",
        body:JSON.stringify(data),
        headers:{
          'Content-Type':'application/JSON',
          'Accept': 'application/JSON'
        },
        mode:'cors' 
      })
    
      .then(response => response.json())
      .then(response => { 
          alert(response)
  
      })
     


};


  const [variant, setVariant] = useState("");

 function handleChange(event) {
    setVariant(event.target.value);
  }


  async function handleVariantChange(event) {
    let selectedVariant =   $('.variantType').val()

    if(selectedVariant === 'others'){
        $('.variantAlt').fadeIn('slow') 
     }else{
         $('.variantAlt').fadeOut('fast')  
     }


  
  }

    return(
      <>
      <div className="col-12 grey-strip-height ford-grey navigation-clearance mobile-margin-clearance">

</div>
      <div className="row ford-font full-width mROff">  
      <img src={props.registerBanner} className="pOff" />
      </div>
     
     <div className='row ford-font full-width navigation-clearance-democratic-banner mROff'>

        <div className="col-12 col-md-8 mx-auto standard-margin-bottom text-center">
        <h4 className="ford-royal-blue-text"> By registering your Ford vehicle officially, you get access to limited time discounts, invitation to community events and freebies from Ford. </h4>
        <h6 className="ford-royal-blue-text light-font-weight"> Provide basic details about your vehicle and a service advisor will contact after verification </h6>
        
        </div>

        <div className="col-12">

        <div className='p-5 text-center'>
                    <form className='col-12 col-md-6 mx-auto' onSubmit={handleSubmit(onSubmit)}>

                            <input className='form-control' type="text" default='First name' {...register("firstname", {required:true})} />

                            <input className='form-control' type="text" placeholder='Lastname' {...register("lastname", {required:true})}/>

                            <input className='form-control' type="email" placeholder='Email'  {...register("email", {required:true})}/>

                            <input className='form-control' type="text" placeholder='Mobile No' {...register("mobileno", {required:true})}/>

                            <input className='form-control' type="text" placeholder='Licence Plate' {...register("plate", {required:true})}/>

                            <select className='form-control variantType' {...register("variant", {required:true})} onChange={handleVariantChange}>
                                <option value="none">Select a variant</option>
                                <option value="figo">Figo</option>
                                <option value="focus">Focus</option>
                                <option value="fusion">Fusion</option>
                                <option value="ecosport">Ecosport</option>
                                <option value="escape">Escape</option>
                                <option value="ranger">Ranger</option>
                                <option value="f-150">F-150</option>
                                <option value="explorer">Explorer</option>
                                <option value="everest">Everest</option>
                                <option value="others">Others</option>
                            </select>

                            <input className='form-control variantAlt' {...register("variantName")} type="text" placeholder='Type your Variant'/>

                            <select className='form-control ford-royal-blue-font' {...register("model", {required:true})}>

                                <option value="none">Select a Model Year </option>
                                <option value="2022"> 2022 </option>
                                <option value="2021"> 2021 </option>
                                <option value="2020"> 2020 </option>
                                <option value="2019"> 2019 </option>
                                <option value="2018"> 2018 </option>
                                <option value="2017"> 2017 </option>
                                <option value="2016"> 2016 </option>
                                <option value="2015"> 2015 </option>
                                <option value="2014"> 2014 </option>
                                <option value="2013"> 2013 </option>
                                <option value="2012"> 2012 </option>
                                <option value="2011"> 2011 </option>
                                <option value="2010"> 2010 </option>
                                <option value="2009"> 2009 </option>
                                <option value="2008"> 2008 </option>
                                
                            </select>

                      

                            <input className='form-control' type="text" placeholder='VIN' {...register("vin", {required:true})}/>

                            <select className='form-control ford-royal-blue-font' {...register("registrationStatus", {required:true})}>

<option value="none">Is your Ford Registered with Coscharis ? </option>
<option value="Yes"> Yes </option>
<option value="No"> No </option>
<option value="Not sure"> Not Sure </option>



</select>

                            <div className='col-12 text-right'>
                            <button className='btn btn-ford-blue'> Book a service </button>
                            </div>
                    </form>
                </div>

        </div>

       

     </div>

        </>
    )
}

export default Register;