import React, { useState, useEffect, Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import BannerSlider from './bannerSlider';
import GoFurther from './Gofurther';
import Ahead from './ahead';
import Charis from './Charis';
import Personalize from './personalize';
import FinanceAd from './financeAd'; 
import Aftersales from './aftersales';
import $ from 'jquery';
import DemocraticBanners from './democraticBanners';
import randomInteger from 'random-int';
import Footer from '../footer/footer';
import '../../component/homepage/homepage.css'

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
import democraticBanners from './democraticBanners';
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });





const Homepage = () => {

    window.scrollTo(0, 0)


    const [homeBanner, setHomeBanner] = useState([]);
    const [viewRangeBanner, setViewRangeBanner] = useState("");
    const [charisBanner, setCharisBanner] = useState("");
    const [aftersalesBanner, setAftersalesBanner] = useState("");
    const [financeBanner, setFinanceBanner] = useState("");
    const [adBanners, setAdBanners] = useState("");
    const [adCopy, setAdCopy] = useState("");
    const [adLink, setAdLink] = useState("");
    
    

    
    

    let adBannerDetails = []


    useEffect( () => {
    

     
        client
      .query({
        query: gql`
        query{
         pages {
           edges {
             node {
               homepage {
                 fieldGroupName
                 applyingForFinance {
                   id
                   mediaItemUrl
                 }
                 demcraticBanners {
                   variantImage {
                     mediaItemUrl
                   }
                   variantMarketingMessage
                   variantUrl
                 }
                 exploreFordAftersales {
                   mediaItemUrl
                 }
                 getStartedImage {
                   mediaItemUrl
                 }
                 seeRangeImage {
                   mediaItemUrl
                 }
                 homepageBanner {
                   imageLink {
                     mediaItemUrl
                   }
                 }
               }
             }
           }
         }
        }
    
        `,
      })
      .then(async result => {
        console.log(result.data.pages.edges)
        adBannerDetails =  result.data.pages.edges[4].node.homepage.demcraticBanners
        let bannerSelectionInt = randomInteger(0, adBannerDetails.length - 1 )
        console.log(bannerSelectionInt)
        setAdBanners(result.data.pages.edges[4].node.homepage.demcraticBanners[bannerSelectionInt].variantImage.mediaItemUrl)
        setAdLink(result.data.pages.edges[4].node.homepage.demcraticBanners[bannerSelectionInt].variantUrl)
        setAdCopy(result.data.pages.edges[4].node.homepage.demcraticBanners[bannerSelectionInt].variantMarketingMessage)
       

       
        console.log(result.data.pages.edges[4].node.homepage.demcraticBanners[bannerSelectionInt].variantImage.mediaItemUrl)
         
       
        setHomeBanner(result.data.pages.edges[4].node.homepage.homepageBanner)
        setCharisBanner(result.data.pages.edges[4].node.homepage.getStartedImage.mediaItemUrl)
        setViewRangeBanner(result.data.pages.edges[4].node.homepage.seeRangeImage.mediaItemUrl)
        setAftersalesBanner(result.data.pages.edges[4].node.homepage.exploreFordAftersales.mediaItemUrl)
        setFinanceBanner(result.data.pages.edges[4].node.homepage.applyingForFinance.mediaItemUrl)
      
        //get random selection for Democratic Banners 

  
        
       });


   
 



     }, [])






  return (
    <>
        <BannerSlider gethomeBanner = {homeBanner}/>
        <GoFurther/>
        <Ahead getRange = {viewRangeBanner} />
        <Charis getCharis = {charisBanner}/>
        <Personalize/>
        <FinanceAd getFinanceBanner = {financeBanner}/>
        <Aftersales getAftersalesBanner = {aftersalesBanner}/>
        <DemocraticBanners getAdBanners = {adBanners} getAdLink={adLink} getAdCopy={adCopy}/>

        
      
    </>
) 




   
}

export default Homepage;