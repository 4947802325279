import React, { useState, useEffect, Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import $ from 'jquery';
import Parts from './parts';
import Register from './register';
import aftersales from '../../assets/images/Ford-Aftersales-Banner.jpg';
import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });




const Aftersales = () => {



    window.scrollTo(0, 0)


  async function parts (form){
    $('#submit-parts').click( async function(e){ 
      e.preventDefault()
     console.log('clicked')
     alert(JSON.stringify(form))
     let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/partsRequest',{ 
         method: "POST",
         body:JSON.stringify(form),
         headers:{
           'Content-Type':'application/JSON',
           'Accept': 'application/JSON'
         },
         mode:'cors' 
       })
     
       .then(response => response.json())
       .then(response => { 
           alert(response)
   
       })
      
 });
 
         }


    const [pageAftersalesBanner, setPageAftersalesBanner] = useState("");
    const [pageRegisterBanner, setRegisterBanner] = useState("");
    const [pagePartsBanner, setPartsBanner] = useState("");




    const getTabFromPathname = (pathname) => {
        switch (pathname) {
          case "http://localhost:3000/aftersales":
            return true;
          case "http://localhost:3000/aftersales#register":
            return true;
          case "http://localhost:3000/aftersales#parts":
            return false;
        }
      };

      useEffect(() => {


        async function parts (form){
          $('#submit-parts').click( async function(e){ 
            e.preventDefault()
           console.log('clicked')
           alert(JSON.stringify(form))
           let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/partsRequest',{ 
               method: "POST",
               body:JSON.stringify(form),
               headers:{
                 'Content-Type':'application/JSON',
                 'Accept': 'application/JSON'
               },
               mode:'cors' 
             })
           
             .then(response => response.json())
             .then(response => { 
                 alert(response)
         
             })
            
       });
       
               }

               parts()
      
        

        if (getTabFromPathname(window.location.href) === true){
            let tabElementParts = document.getElementById('aftersales-tab-tab-parts')
            let tabElementRegister = document.getElementById('aftersales-tab-tab-register')
            $(tabElementRegister).addClass('active')
            $(tabElementParts).removeClass('active')
         }
    
         if (getTabFromPathname(window.location.href) === false){
          
            let tabElementParts = document.getElementById('aftersales-tab-tab-parts')
            let tabElementRegister = document.getElementById('aftersales-tab-tab-register')
            $(tabElementParts).addClass('active')
            $(tabElementRegister).removeClass('active')
           
         }
      
         if (getTabFromPathname(window.location.href) === null){
            return null
         }

         
         client
       .query({
         query: gql`
         query{
           pages {
             nodes {
               aftersales {
                
                 firstBannerImage {
          
                   mediaItemUrl
                 }
            
                 secondBannerImage {
         
                   mediaItemUrl
                 }
           
                 thirdBannerImage {
         
                   mediaItemUrl
                 }
               }
             }
           }
         }
     
         `,
       })
       .then(result => {
         console.log(result.data.pages.nodes)
          setPageAftersalesBanner(result.data.pages.nodes[3].aftersales.firstBannerImage.mediaItemUrl)
          setRegisterBanner(result.data.pages.nodes[3].aftersales.thirdBannerImage.mediaItemUrl) 
          setPartsBanner(result.data.pages.nodes[3].aftersales.secondBannerImage.mediaItemUrl)
       });
   
      }, [])
  
       console.log(getTabFromPathname(window.location.href))


    return(
        
        <>
        <div className="forBlog navigation-clearance mobile-margin-clearance-banner">
                <h5 className='mOff'>Ford Owner Services </h5>
            </div>
      <div className="row ford-font full-width mOff">  
      <img src={pageAftersalesBanner} className="pOff" />
      </div>
     
     <div className='row ford-font full-width navigation-clearance-democratic-banner mROff'>

        <div className="col-12 standard-margin-bottom text-center">
        <h4 className="ford-royal-blue-text"> Get Started With Ford Owner Services Online Today </h4>
        </div>

        <div className="col-12">

        <Tabs
      defaultActiveKey="register"
      id="aftersales-tab"
      className="mb-3 col-12 col-md-5 mx-auto"
      justify
       >
      <Tab eventKey="register" title="Book a service" className="register-tab">
       <Register registerBanner={pageRegisterBanner}/>
      </Tab>
      <Tab eventKey="parts" title="Request Parts" className="parts-tab">
      <Parts partsBanner={pagePartsBanner} partsRequest={parts}/> 
      </Tab>
     
    </Tabs>
        </div>

       

     </div>

        </>
        
    )
}

export default Aftersales;