import React, { useState, useEffect, Component } from 'react';
import { Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import escape from '../../assets/images/ford-personalize.jpg';
import { get } from 'react-scroll/modules/mixins/scroller';



const Personalize = () => {

   const[personalizedData, setPersonalizedCarData] = useState("")
   const[banner, setBanner] = useState("")
   const[minPrice, setMinPrice] = useState("")
   const[categoryId, setCategoryId] = useState("")
   const[category, setCategory] = useState("")
   const[variant, setVariant] = useState("")

   useEffect( () => {
   async function  getPersonalizationData(){ 


    let personalizationData = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/personalisationd/retrievevariantData',{ 
      method: "POST",
      headers:{
        'Content-Type':'application/JSON',
        'Accept': 'application/JSON'
      },
      mode:'cors',
      credentials:'include'  
    })
  
    .then(response => response.json())
    .then(response => { 
        console.log(response)
     setPersonalizedCarData(response)
    })
   }


   getPersonalizationData();
  }, [])



   

   let personalizationDataDetails

   if(personalizedData.length){

   personalizationDataDetails = personalizedData.map((personalizationDataDetails, i) => 

   <div className="col-12 col-md-4 mx-auto ford-font text-white navigation-clearance mobile-margin-clearance standard-text-padding">
         
   <Card className="ford-font">
<Card.Img variant="top" src={personalizationDataDetails.banner} />
<Card.Body className="ford-blue-bg">
 <Card.Text>
  <h4 className="text-left"> {personalizationDataDetails.variant}</h4>
  <h6 className="text-left light-font-weight">{personalizationDataDetails.minPrice}</h6> 
  <a class="nav-link nav-font-size text-right" href={`/test-drive/${personalizationDataDetails.variant}`} target="_blank">
  <button className='btn btn-ford-blue-shadow ford-font ford-button-padding'>Test Drive</button>
  </a>

 </Card.Text>
     </Card.Body>
     <div className="col-12 standard-section-margin text-center">
     <a class="nav-link nav-font-size" href={`/brands/variants/${personalizationDataDetails.categoryName}/${personalizationDataDetails.catid}/${personalizationDataDetails.variant}`}>
     <button className="btn btn-outline ford-font ford-outline-button-blue ford-button-padding-alt">Explore </button>
     </a>
     </div>
</Card>
  
   </div>

     )

   }

     if(!personalizedData.length){

    return(
    <div className="row full-width mOff">
    
    </div>
 
  

        
    )
    }


    if(personalizedData.length){

      return(
      <div className="row ford-font navigation-clearance mobile-margin-clearance full-width mOff">
            <div className="col-12 text-center pOff">
  
              <h1 className="ford-blue-text"> 
                Pick up where you left off
              </h1>
               
            </div>
  
        {personalizationDataDetails}
  
        
      </div>
   
    
  
          
      )
      }

}

export default Personalize;