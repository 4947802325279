import React, { useState, useEffect, Component } from 'react';
import { useForm } from "react-hook-form";
import { Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import finance1 from '../../assets/images/bronco.jpg';
import broncoMob from '../../assets/images/bronco-mob.jpg';
import financecar from '../../assets/images/t-edge.jpg';
import processcar from '../../assets/images/f-edge.jpg';
import '../finance/finance.css'
import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://cg-blackfriday.inspireleadership.biz/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });






 

const Finance = () => {



    let { register, handleSubmit, watch, formState: { errors } } = useForm()
    let onSubmit = async function (data){
      
          
      
          
        let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/finance',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };

    window.scrollTo(0, 0)
    
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState("");
    const [financeBanner, setFinanceBanner]= useState("");
    const [financeBannerMobile, setFinanceBannerMobile] = useState("");
    const [carSwapBanner, setcarSwapBanner] = useState("");
    const [carLeaseBanner, setcarLeaseBanner] = useState("");

 function handleChange(event) {
    setVariant(event.target.value);
  }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
     
        client
      .query({
        query: gql`
        query{
            pages {
                edges {
                  node {
                    finance {
                      financeBanner{
                        mediaItemUrl
                      }
                      financeBannerMobile{
                        mediaItemUrl
                      }
                      
                      carLoanBanner{
                        mediaItemUrl
                      }
                      carSwapBanner{
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
        }
    
        `,
      })
      .then(result => {
        console.log(result.data.pages.edges)
        setFinanceBanner(result.data.pages.edges[5].node.finance.financeBanner.mediaItemUrl)
        setFinanceBannerMobile(result.data.pages.edges[5].node.finance.financeBannerMobile.mediaItemUrl)
        setcarSwapBanner(result.data.pages.edges[5].node.finance.carLoanBanner.mediaItemUrl)
        setcarLeaseBanner(result.data.pages.edges[5].node.finance.carSwapBanner.mediaItemUrl)
            });
   
     }, [])
   

    return (

        <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ford-font ford-blue-text">
          <h4 className="ford-font ford-blue-text">
             Getting started with vehicle finance
             </h4>
           <h6 className="ford-font ford-grey-text"> Provide your details below to start the process and we will contact you on next steps </h6>  
             </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
        <div className="col-12 grey-strip-height ford-grey">

         </div>   

         <div className="col-12">

<div className='p-5 text-center'>
            <form className='col-12 col-md-6 mx-auto' onSubmit={handleSubmit(onSubmit)}>

                    <input name="firstname" className='form-control' type="text" placeholder='Firstname'  {...register("firstname", {required:true})}/>

                    <input name="lastname" className='form-control' type="text" placeholder='lastname'  {...register("lastname", {required:true})}/>

                    <input name="email" className='form-control' type="email" placeholder='Email'  {...register("email", {required:true})}/>

                    <input name="mobileno" className='form-control' type="text" placeholder='Mobile No'  {...register("mobileno", {required:true})}/>

                    <select name="variant" className='form-control'  {...register("variant", {required:true})}>
                        <option value="none">Select a variant</option>
                        <option value="figo">Figo</option>
                        <option value="focus">Focus</option>
                        <option value="fusion">Fusion</option>
                        <option value="ecosport">Ecosport</option>
                        <option value="escape">Escape</option>
                        <option value="ranger">Ranger</option>
                        <option value="f-150">F-150</option>
                        <option value="explorer">Explorer</option>
                        <option value="everest">Everest</option>
                    </select>

                    
                    <select name="employment" className='form-control ford-royal-blue-font' {...register("employmentStatus", {required:true})}>
                        <option value="none">Employment Status</option>
                        <option value="1"> Self-Employed </option>
                        <option value="2"> Employed </option>
                        <option value="3"> Unemployed </option>
                        <option value="4"> Between Jobs </option>
                    </select>

                    <input name="organisation" className='form-control' type="text" placeholder='Organisation name'  {...register("organisation", {required:true})}/>

             <div className='col-12 text-right'>
             <button className='btn btn-ford-blue'>Submit request</button>
             </div>
              
            </form>
        </div>

</div>
            
            </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-ford-blue' variant="secondary">
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
            <div className='row mobile-margin-clearance-banner'>
                <div className='col-12 d-none d-sm-block'>
                    <img src={financeBanner}  alt="" width="100%"/>
                </div>
                <div className='col-12 d-block d-sm-none'>
                    <img src={financeBannerMobile}  alt="" width="100%"/>
                </div>
                <div className='col-12 track text-center p-5'>
                    <h3 className='ford-font ford-blue-text light-font-weight'>Finance Tracks</h3>
                    <p className='ford-font ford-grey-text light-font-weight'>We offer you value-added service to assist in getting your new Ford without a 100% cash payment</p>
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-md-6 finance-padding'>
                    
                        <div className='row container-fluid'>
                            <div className='col-12 col-md-4 carKey'>
                                <i class="fa-solid fa-key"></i>
                            </div>
                            <div className='col-12 col-md-8 swapHeading'>
                                <h4 className='ford-font ford-blue-text'>Car Lease Scheme</h4>
                                <p className='ford-font ford-grey-text light-font-weight'>Apply for qualification for a car lease requiring
                                    a minimum of 10% down-payment and security deposit with payment spread of up to 3years with
                                     our finance partners
                                </p>

                                <a href="#">Read More <i class="fa-solid fa-angle-right"></i></a>
                            </div>
                        </div>
                    
                </div>
                <div className='col-12 col-md-6 finance-padding'>
                    
                        <div className='row container-fluid'>
                            <div className='col-12 col-md-4 carKey'>
                            <i class="fa-solid fa-arrows-rotate"></i>
                            </div>
                            <div className='col-12 col-md-8 swapHeading'>
                                <h4 className='ford-font ford-blue-text'>Car Swap Service</h4>
                                <p className='ford-font ford-grey-text light-font-weight'>Through our vehicle valuation unit, you can submit specific details about 
                                    your current Ford to find out if you qualify for a trade-in finance..
                                </p>

                                <a href="#">Read More <i class="fa-solid fa-angle-right"></i></a>
                            </div>
                        </div>
                </div>
            </div>

            <div className='row'>
                    <div className='col-12'>
                        <img src={carLeaseBanner}  alt="" width="100%"/>
                    </div>
            </div>

            <div className='row'>
                <div className='col-12 track text-center p-5'>
                    <h3 className='ford-font ford-blue-text light-font-weight'>Car Lease Scheme</h3>
                    <p className='ford-font ford-grey-text light-font-weight'>Make a 10% minimum deposit and security deposit to drive away with any Ford vehicle today.</p>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row container-fluid'>
                    <div className='col-12 equity'>
                        <h4 className='ford-font ford-blue-text '>Build Equity With Each Payment</h4>
                        <p className='ford-font ford-grey-text light-font-weight'>With terms ranging from 12 - 36 months, you can choose the payment 
                            schedule that best fits your needs. Equityis built with each payment, lowering the 
                            purchase price your next Ford.</p>
                    </div>
                    <div className='col-12 equity'>
                        <h4 className='ford-font ford-blue-text light-font-weight'>The Benefits</h4>
                        <ul>
                            <li className='ford-font ford-grey-text light-font-weight'>Drive away with car immediately</li>
                            <li className='ford-font ford-grey-text light-font-weight'>Predictable monthly rates</li>
                            <li className='ford-font ford-grey-text light-font-weight'>Build equity with each payment</li>
                            <li className='ford-font ford-grey-text light-font-weight'>Enjoy owner benefits from day one</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='startProcess text-center m-5'>

                <button onClick={handleShow} className='btn btn-ford-blue ford-font'>Start Application Process</button>
            </div>

            <div className='row'>
                    <div className='col-12'>
                        <img src={carSwapBanner}  alt="" width="100%"/>
                    </div>
            </div>

            <div className='row'>
                <div className='col-12 track text-center p-5'>
                    <h3 className='ford-font ford-blue-text light-font-weight'>Car Swap Service</h3>
                    <p className='ford-font ford-grey-text light-font-weight'>Trade-in your vehicle and pay only the net cost minus your cars value</p>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row container-fluid'>
                    <div className='col-12 equity'>
                        <h4 className='ford-font ford-blue-text'>Commercialize yours asset with Ford</h4>
                        <p className='ford-font ford-grey-text light-font-weight'>Use your current Ford vehicle as leverage to purchase your brand new Ford today.
                            Simply provide information about your current Ford to our valuation team to get started
                        </p>
                    </div>
                    <div className='col-12 equity'>
                        <h4 className='ford-font ford-blue-text'>The Benefits</h4>
                        <ul>
                            <li className='ford-font ford-grey-text light-font-weight'>Save 20% - 50% on your purchase</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='startProcess text-center m-5'>
                <Link to="/trade-in">
                <button className='btn btn-ford-blue ford-font'>Submit Valuation Request</button>
                </Link>
               
            </div>
        </>
    )
}


export default Finance;